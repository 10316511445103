/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */


.news__list {
  --news-gap: #{to-vw-desktop(40px)};
  --news-columns: 4;

  display: grid;
  grid-template-columns: repeat(var(--news-columns), 1fr);
  grid-gap: var(--news-gap);

  @include tablet-landscape {
    --news-gap: 40px;
    --news-columns: 2;
  }

  @include tablet-portrait {
    --news-gap: 24px;

    grid-template-columns: 100%;
  }

  @include mobile {
    --news-gap: 16px;
  }
}


.news__card {
  min-height: to-vw-desktop(256px);
  background-color: $gray-lightest;
  border-radius: var(--card-border-radius);

  // safari fix
  display: flex;
  flex-direction: column;

  @include tablet-landscape {
    min-height: 256px;
  }

  @include tablet-portrait {
    min-height: 236px;
  }

  @include mobile {
    min-height: 158px;
  }
}


.news__card-link {
  --link-color-default: #{$black};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: to-vw-desktop(24px);

  // safari fix
  flex-grow: 1;

  @include tablet-landscape {
    padding: 24px;
  }

  @include mobile {
    padding: 16px;
  }
}


.news__card-title,
.news__card-text {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}


.news__card-title {
  margin-bottom: to-vw-desktop(16px);
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  -webkit-line-clamp: 2;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include tablet-landscape {
    margin-bottom: 16px;
  }

  @include mobile {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1.3;
  }
}


.news__card-text {
  margin-bottom: to-vw-desktop(32px);
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: $gray-darker;

  -webkit-line-clamp: 4;

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include tablet-landscape {
    margin-bottom: 32px;
  }

  @include tablet-portrait {
    -webkit-line-clamp: 3;
  }

  @include mobile {
    margin-bottom: 12px;
    font-size: 12px;
  }
}


.news__card-datetime {
  margin-top: auto;
}
