.contacts__section {
  margin-bottom: to-vw-desktop(64px);

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    margin-bottom: 64px;
  }
}


.contacts__section-title {
  margin-bottom: to-vw-desktop(40px);

  @include tablet-landscape {
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 24px;
  }
}


.contacts__table {
  max-width: to-vw-desktop(800px);

  @include tablet-landscape {
    max-width: 800px;
  }
}
