.table {
  padding: to-vw-desktop(40px) 0;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: $black;
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;

  &--no-borders {
    padding: 0;
    border: none;
  }

  .accordion & {
    padding: 0;
    border: none;
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include tablet-landscape {
    padding: 40px 0;

    &--no-borders {
      padding: 0;
    }
  }

  @include mobile {
    padding: 24px 0;
    font-size: 10px;

    &--no-borders {
      padding: 0;
    }
  }
}


.table,
.table__body {
  display: block;
}


.table__row {
  display: flex;
  margin-bottom: to-vw-desktop(24px);

  &--with-separator {
    padding-bottom: to-vw-desktop(24px);
    border-bottom: 1px solid $gray-lighter;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    margin-bottom: 24px;

    &--with-separator {
      padding-bottom: 24px;
    }
  }

  @include mobile {
    margin-bottom: 16px;

    &--with-separator {
      padding-bottom: 16px;
    }
  }
}


.table__cell {
  flex-grow: 1;
  padding: 0;

  &--head {
    flex-grow: 0;
    flex-shrink: 0;
    width: to-vw-desktop(410px);
    margin-right: to-vw-desktop(40px);
    font-weight: 500;
    text-align: left;
  }

  @include tablet-landscape {
    width: 48%;

    &--head {
      margin-right: 40px;
    }
  }

  @include mobile {
    &--head {
      margin-right: 16px;
    }
  }
}
