.text {
  p {
    margin: 0;
    margin-bottom: to-vw-desktop(24px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  b {
    font-weight: 500;
  }

  &--small {
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.015em;
  }

  @include desktop-small-only {
    &--small {
      font-size: to-vw-desktop(13px);
    }
  }

  @include tablet-landscape {
    p {
      margin-bottom: 24px;
    }
  }

  @include mobile {
    p {
      margin-bottom: 16px;
    }

    &--small {
      font-size: 12px;
      line-height: 1.6;
      letter-spacing: 0;
    }
  }
}
