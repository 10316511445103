.object-intro {
  --object-intro-column-gap: #{to-vw-desktop(40px)};
  --object-intro-row-gap: #{to-vw-desktop(16px)};

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: to-vw-desktop(395px);
  padding-top: to-vw-desktop(200px);
  padding-bottom: to-vw-desktop(40px);
  color: $white;

  @include tablet-landscape {
    --object-intro-column-gap: 40px;
    --object-intro-row-gap: 16px;

    min-height: 375px;
    padding-top: 200px;
    padding-bottom: 36px;
  }

  @include tablet-portrait {
    min-height: 425px;
    padding-top: 176px;
  }

  @include mobile {
    --object-intro-column-gap: 32px;

    min-height: 320px;
    padding-top: 86px;
    padding-bottom: 28px;
  }
}


.object-intro__container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}


.object-intro__title {
  margin-right: calc(2 * var(--object-intro-column-gap));
  color: inherit;

  @include tablet-landscape {
    width: 100%;
    margin-right: 0;
    font-size: 58px;
  }

  @include mobile {
    font-size: 28px;
  }

  @include min-width {
    font-size: 24px;
  }
}


.object-intro__contacts {
  margin-top: to-vw-desktop(24px);

  @include tablet-landscape {
    margin-top: 24px;
  }
}


.object-intro__contacts-list {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--object-intro-row-gap));
  margin-left: calc(-1 * var(--object-intro-column-gap));
}


.object-intro__contact {
  margin-top: var(--object-intro-row-gap);
  margin-left: var(--object-intro-column-gap);

  @include mobile {
    min-width: 94px;
  }
}


.object-intro__contact-title {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em;

  @include desktop-small-only {
    font-size: to-vw-desktop(13px);
  }
}


.object-intro__contact-link {
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include mobile {
    font-size: 16px;
    line-height: 1.3;
  }
}


.object-intro__social {
  flex-wrap: nowrap;
  margin-top: to-vw-desktop(4px);

  @include tablet-landscape {
    margin-top: 4px;
  }
}


.object-intro__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  &::after {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.7);
  }
}
