.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: $viewport-height;
  padding-top: to-vw-desktop(152px);
  padding-bottom: to-vw-desktop(120px);
  font-family: $secondary-font-family;
  color: $white;

  @include tablet-landscape {
    padding-top: 152px;
    padding-bottom: 48px;
  }

  @include tablet-portrait {
    padding-top: 136px;
  }

  @include mobile {
    padding-top: 70px;
    padding-bottom: calc(5 * var(--vh, 1vh));
  }
}


.intro__video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &::after,
  video {
    width: 100%;
    height: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.45);
  }

  video {
    display: block;
    object-fit: cover;
  }
}


.intro__container {
  display: grid;
  grid-template-areas:
    "title contacts"
    "buttons contacts";
  grid-template-columns: 1fr to-vw-desktop(360px);
  grid-template-rows: auto 1fr;
  grid-column-gap: to-vw-desktop(120px);

  @include tablet-landscape {
    display: flex;
    flex-direction: column;
  }

  @include tablet-portrait {
    @media (orientation: landscape) {
      height: 100%;
      align-items: center;
      text-align: center;
    }
  }

  @include mobile {
    height: 100%;
    align-items: center;
    text-align: center;
  }
}


.intro__title {
  grid-area: title;
  margin: 0;
  margin-bottom: to-vw-desktop(48px);
  font-weight: 700;
  font-size: 70px;
  line-height: 1.1;

  @include desktop-small-only {
    font-size: to-vw-desktop(70px);
  }

  @include tablet-landscape {
    margin-bottom: 48px;
    font-size: 62px;
  }

  @include tablet-portrait {
    @media (orientation: landscape) {
      margin-top: auto;
      margin-bottom: calc(5 * var(--vh, 1vh));
      font-size: 32px;
    }
  }

  @include mobile {
    margin-top: auto;
    margin-bottom: calc(5 * var(--vh, 1vh));
    font-size: 32px;
  }
}


.intro__buttons-wrapper {
  @include tablet-landscape {
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: 1px solid rgba($white, 0.4);
  }

  @include mobile {
    width: 100%;
    max-width: 400px;
    padding-bottom: 0;
    margin: 0 auto calc(5 * var(--vh, 1vh));
    border-bottom: none;
  }
}


.intro__buttons {
  grid-area: buttons;

  @include not-mobile {
    --intro-buttons-gap: #{to-vw-desktop(16px)};

    display: flex;
    margin-top: calc(-1 * var(--intro-buttons-gap));
    margin-left: calc(-1 * var(--intro-buttons-gap));
  }

  @include tablet-landscape {
    --intro-buttons-gap: 16px;
  }

  @include tablet-portrait {
    @media (orientation: landscape) {
      display: none;
    }
  }

  @include mobile {
    margin: 0;
  }
}


.intro__button {
  margin-top: var(--intro-buttons-gap);
  margin-left: var(--intro-buttons-gap);

  @include mobile {
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.intro__contacts {
  @include list-reset;

  grid-area: contacts;
  align-self: end;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include tablet-landscape {
    display: flex;
    flex-wrap: wrap;
    margin-top: -40px;
    margin-left: -40px;
  }

  @include tablet-portrait {
    @media (orientation: landscape) {
      align-self: center;
    }
  }

  @include mobile {
    align-self: center;
    display: block;
    margin: 0;
    margin-top: auto;
    font-size: 16px;
    line-height: 1.3;
  }
}


.intro__contact-item {
  margin-bottom: to-vw-desktop(10px);

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    margin-top: 40px;
    margin-left: 40px;
    margin-bottom: 0;
  }

  @include mobile {
    margin: 0;
    margin-bottom: 10px;
  }
}


.intro__contact-link {
  display: inline-flex;
  align-items: center;

  svg {
    width: 26px;
    height: 26px;
    margin-right: 14px;
  }

  @include desktop-small-only {
    svg {
      width: to-vw-desktop(26px);
      height: to-vw-desktop(26px);
      margin-right: to-vw-desktop(14px);
    }
  }

  @include mobile {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
