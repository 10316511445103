/* stylelint-disable */
/* stylelint-disable */
/* stylelint-enable */
@font-face {
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../font/tt-norms-pro-400.woff2) format("woff2"); }

@font-face {
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../font/tt-norms-pro-500.woff2) format("woff2"); }

@font-face {
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../font/tt-norms-pro-700.woff2) format("woff2"); }

@font-face {
  font-family: "TT Travels";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../font/tt-travels-400.woff2) format("woff2"); }

@font-face {
  font-family: "TT Travels";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../font/tt-travels-600.woff2) format("woff2"); }

@font-face {
  font-family: "TT Travels";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../font/tt-travels-700.woff2) format("woff2"); }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

*,
*::after,
*::before {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

body {
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased; }

@-moz-document url-prefix("") {
  body {
    -moz-osx-font-smoothing: grayscale; } }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font: inherit;
  letter-spacing: inherit;
  color: inherit; }

input::-moz-placeholder, textarea::-moz-placeholder {
  font: inherit;
  letter-spacing: inherit;
  color: inherit; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font: inherit;
  letter-spacing: inherit;
  color: inherit; }

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font: inherit;
  letter-spacing: inherit;
  color: inherit; }

button,
input,
input::placeholder,
textarea,
textarea::placeholder,
select {
  font: inherit;
  letter-spacing: inherit;
  color: inherit; }

a {
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

.ios-num-fix * {
  color: inherit;
  text-decoration: none;
  pointer-events: none; }

:root {
  --card-border-radius: 0.10417vw; }
  @media (max-width: 1365px) {
    :root {
      --card-border-radius: 2px; } }

.container {
  --container-padding: 4.16667vw;
  width: 100%;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin: 0 auto; }
  @media (min-width: 1366px) {
    .container--medium {
      max-width: 84.375vw; }
    .container--narrow {
      max-width: 68.75vw; } }
  @media (max-width: 1365px) {
    .container {
      --container-padding: 40px; } }
  @media (max-width: 767px) {
    .container {
      --container-padding: 16px; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

/* stylelint-disable declaration-no-important */
.hidden {
  display: none !important; }

/* stylelint-enable declaration-no-important */
.button {
  --button-cover-opacity-default: 0;
  --button-cover-opacity-interactive: 1;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.625vw 2.08333vw;
  font-family: "TT Norms Pro", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  user-select: none; }
  .button svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: var(--button-cover-opacity-default);
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in; }
  .button svg {
    width: 20px;
    height: 20px;
    margin-left: 15px; }
  .button--small {
    padding: 0.41667vw 1.25vw;
    font-size: 15px;
    line-height: 1.4; }
    .button--small svg {
      width: 12px;
      height: 12px;
      margin-left: 10px; }
  .button--bg-white {
    color: #000000;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0.26042vw 0.52083vw rgba(0, 0, 0, 0.07), 0 0.05208vw 0.10417vw rgba(0, 0, 0, 0.12);
            box-shadow: 0 0.26042vw 0.52083vw rgba(0, 0, 0, 0.07), 0 0.05208vw 0.10417vw rgba(0, 0, 0, 0.12); }
    .button--bg-white::before {
      background-color: rgba(0, 0, 0, 0.05); }
  .button--bg-cyan {
    color: #ffffff;
    background-color: #22cce2; }
    .button--bg-cyan::before {
      background-color: rgba(255, 255, 255, 0.2); }
  .button--border-white {
    color: #ffffff;
    -webkit-box-shadow: inset 0 0 0 2px #ffffff;
            box-shadow: inset 0 0 0 2px #ffffff; }
    .button--border-white::before {
      background-color: rgba(255, 255, 255, 0.1); }
  .button--border-black {
    color: #000000;
    -webkit-box-shadow: inset 0 0 0 2px #000000;
            box-shadow: inset 0 0 0 2px #000000; }
    .button--border-black::before {
      background-color: rgba(0, 0, 0, 0.05); }
  .button:focus::before {
    opacity: var(--button-cover-opacity-interactive); }
  .button:focus:not(.focus-visible)::before {
    opacity: var(--button-cover-opacity-default); }
  .button:active:not(.focus-visible)::before {
    opacity: var(--button-cover-opacity-interactive); }
  @media (hover: hover) {
    .button:hover:not(.focus-visible)::before {
      opacity: var(--button-cover-opacity-interactive); } }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .button {
      font-size: 1.04167vw; }
      .button svg {
        width: 1.04167vw;
        height: 1.04167vw;
        margin-left: 0.78125vw; }
      .button--small {
        font-size: 0.78125vw; }
        .button--small svg {
          width: 0.625vw;
          height: 0.625vw;
          margin-left: 0.52083vw; } }
  @media (max-width: 1365px) {
    .button {
      padding: 12px 40px; }
      .button--small {
        padding: 8px 24px; } }
  @media (max-width: 767px) {
    .button {
      padding-left: 24px;
      padding-right: 24px;
      font-size: 16px;
      line-height: 1.3; }
      .button--small {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6; } }

.button__pointer {
  position: absolute;
  z-index: 2;
  display: block;
  width: 50%;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  -webkit-transition: opacity 0.3s ease-in, -webkit-transform 0.5s ease-in;
  transition: opacity 0.3s ease-in, -webkit-transform 0.5s ease-in;
  transition: opacity 0.3s ease-in, transform 0.5s ease-in;
  transition: opacity 0.3s ease-in, transform 0.5s ease-in, -webkit-transform 0.5s ease-in; }
  .button__pointer::after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  .button--bg-white .button__pointer,
  .button--border-black .button__pointer {
    background-color: rgba(0, 0, 0, 0.05); }
  .button--bg-cyan .button__pointer,
  .button--border-white .button__pointer {
    background-color: rgba(255, 255, 255, 0.1); }
  .button__pointer.shown {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1); }

.link {
  --link-color-default: inherit;
  --link-color-interactive: #22cce2;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  color: var(--link-color-default);
  -webkit-transition: color 0.3s ease-in;
  transition: color 0.3s ease-in; }
  .link svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .link--underline {
    text-decoration: underline; }
  .link--reverse {
    --link-color-default: #22cce2;
    --link-color-interactive: inherit; }
  .link:not([href]) {
    pointer-events: none; }
  .link:focus {
    color: var(--link-color-interactive); }
  .link:focus:not(.focus-visible) {
    color: var(--link-color-default); }
  .link:active:not(.focus-visible) {
    color: var(--link-color-interactive); }
  @media (hover: hover) {
    .link:hover:not(.focus-visible) {
      color: var(--link-color-interactive); } }

.back-link {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6; }
  .back-link svg {
    width: 5.83333vw;
    height: 0.41667vw;
    margin-top: 0.26042vw; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .back-link {
      font-size: 1.04167vw; } }
  @media (max-width: 1365px) {
    .back-link svg {
      width: 112px;
      height: 8px;
      margin-top: 5px; } }
  @media (max-width: 767px) {
    .back-link {
      font-size: 14px; }
      .back-link svg {
        width: 80px;
        height: 6px; } }

.title {
  margin: 0;
  font-family: "TT Travels", sans-serif;
  color: #000000; }
  .title--h1 {
    font-weight: 700;
    font-size: 70px;
    line-height: 1.1; }
    @media (min-width: 1366px) and (max-width: 1919px) {
      .title--h1 {
        font-size: 3.64583vw; } }
    @media (max-width: 767px) {
      .title--h1 {
        font-size: 34px; } }
  .title--h2 {
    font-weight: 600;
    font-size: 57px;
    line-height: 1.15;
    letter-spacing: -0.006em; }
    @media (min-width: 1366px) and (max-width: 1919px) {
      .title--h2 {
        font-size: 2.96875vw; } }
    @media (max-width: 767px) {
      .title--h2 {
        font-weight: 700;
        font-size: 30px; } }
    @media (max-width: 374px) {
      .title--h2 {
        font-size: 24px; } }
  .title--h3 {
    font-weight: 600;
    font-size: 42px;
    line-height: 1.15;
    letter-spacing: -0.006em; }
    @media (min-width: 1366px) and (max-width: 1919px) {
      .title--h3 {
        font-size: 2.1875vw; } }
    @media (max-width: 767px) {
      .title--h3 {
        font-weight: 700;
        font-size: 25px;
        line-height: 1.1; } }
  .title--h4 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.005em; }
    @media (min-width: 1366px) and (max-width: 1919px) {
      .title--h4 {
        font-size: 1.66667vw; } }
    @media (max-width: 767px) {
      .title--h4 {
        font-size: 21px;
        line-height: 1.1; } }
  .title--h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2; }
    @media (min-width: 1366px) and (max-width: 1919px) {
      .title--h5 {
        font-size: 1.25vw; } }
    @media (max-width: 767px) {
      .title--h5 {
        font-size: 16px;
        line-height: 1.3; } }
  .title--h6 {
    font-family: "TT Norms Pro", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.005em; }
    @media (min-width: 1366px) and (max-width: 1919px) {
      .title--h6 {
        font-size: 1.04167vw; } }
    @media (max-width: 767px) {
      .title--h6 {
        font-size: 14px;
        letter-spacing: 0; } }

.text p {
  margin: 0;
  margin-bottom: 1.25vw; }
  .text p:last-child {
    margin-bottom: 0; }

.text b {
  font-weight: 500; }

.text--small {
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.015em; }

@media (min-width: 1366px) and (max-width: 1919px) {
  .text--small {
    font-size: 0.67708vw; } }

@media (max-width: 1365px) {
  .text p {
    margin-bottom: 24px; } }

@media (max-width: 767px) {
  .text p {
    margin-bottom: 16px; }
  .text--small {
    font-size: 12px;
    line-height: 1.6;
    letter-spacing: 0; } }

.notice {
  margin: 0;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: #000000; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .notice {
      font-size: 1.25vw; } }
  @media (max-width: 767px) {
    .notice {
      font-size: 16px;
      line-height: 1.3; } }

.text-content__section {
  margin-bottom: 2.5vw; }
  .text-content__section:last-child {
    margin-bottom: 0; }
  @media (max-width: 1365px) {
    .text-content__section {
      margin-bottom: 48px; } }
  @media (max-width: 767px) {
    .text-content__section {
      margin-bottom: 32px; }
      .text-content__section--subsection {
        margin-bottom: 24px; } }

.text-content .title--h1 {
  margin-bottom: 2.5vw; }

.text-content .title--h2 {
  margin-bottom: 2.5vw; }

.text-content .title--h3 {
  margin-bottom: 2.5vw; }

.text-content .title--h4 {
  margin-bottom: 1.66667vw; }

.text-content .title--h5 {
  margin-bottom: 2.08333vw; }

.text-content .title--h6 {
  margin-bottom: 0.83333vw; }

@media (max-width: 1365px) {
  .text-content .title--h1 {
    margin-bottom: 48px; }
  .text-content .title--h2 {
    margin-bottom: 48px; }
  .text-content .title--h3 {
    margin-bottom: 48px; }
  .text-content .title--h4 {
    margin-bottom: 32px; }
  .text-content .title--h5 {
    margin-bottom: 40px; }
  .text-content .title--h6 {
    margin-bottom: 16px; } }

@media (max-width: 767px) {
  .text-content .title--h1 {
    margin-bottom: 32px; }
  .text-content .title--h2 {
    margin-bottom: 32px; }
  .text-content .title--h3 {
    margin-bottom: 24px; }
  .text-content .title--h4 {
    margin-bottom: 16px; }
  .text-content .title--h5 {
    margin-bottom: 16px; }
  .text-content .title--h6 {
    margin-bottom: 8px; } }

.text-content__disclaimer {
  margin-bottom: 2.08333vw; }
  .text-content__disclaimer:last-child {
    margin-bottom: 0; }
  @media (min-width: 1366px) {
    .text-content__disclaimer {
      max-width: 60.41667vw; }
      .text-content__disclaimer--small {
        max-width: 44.27083vw; } }
  @media (max-width: 1365px) {
    .text-content__disclaimer {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .text-content__disclaimer {
      margin-bottom: 16px; } }

.text-content .text,
.text-content .notice,
.text-content .table,
.text-content .image {
  margin-bottom: 2.5vw; }
  .text-content .text:last-child,
  .text-content .notice:last-child,
  .text-content .table:last-child,
  .text-content .image:last-child {
    margin-bottom: 0; }
  @media (max-width: 1365px) {
    .text-content .text,
    .text-content .notice,
    .text-content .table,
    .text-content .image {
      margin-bottom: 48px; } }
  @media (max-width: 767px) {
    .text-content .text,
    .text-content .notice,
    .text-content .table,
    .text-content .image {
      margin-bottom: 24px; } }

.table {
  padding: 2.08333vw 0;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: #000000;
  border-top: 1px solid #ebedef;
  border-bottom: 1px solid #ebedef; }
  .table--no-borders {
    padding: 0;
    border: none; }
  .accordion .table {
    padding: 0;
    border: none; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .table {
      font-size: 0.78125vw; } }
  @media (max-width: 1365px) {
    .table {
      padding: 40px 0; }
      .table--no-borders {
        padding: 0; } }
  @media (max-width: 767px) {
    .table {
      padding: 24px 0;
      font-size: 10px; }
      .table--no-borders {
        padding: 0; } }

.table,
.table__body {
  display: block; }

.table__row {
  display: flex;
  margin-bottom: 1.25vw; }
  .table__row--with-separator {
    padding-bottom: 1.25vw;
    border-bottom: 1px solid #ebedef; }
  .table__row:last-child {
    margin-bottom: 0; }
  @media (max-width: 1365px) {
    .table__row {
      margin-bottom: 24px; }
      .table__row--with-separator {
        padding-bottom: 24px; } }
  @media (max-width: 767px) {
    .table__row {
      margin-bottom: 16px; }
      .table__row--with-separator {
        padding-bottom: 16px; } }

.table__cell {
  flex-grow: 1;
  padding: 0; }
  .table__cell--head {
    flex-grow: 0;
    flex-shrink: 0;
    width: 21.35417vw;
    margin-right: 2.08333vw;
    font-weight: 500;
    text-align: left; }
  @media (max-width: 1365px) {
    .table__cell {
      width: 48%; }
      .table__cell--head {
        margin-right: 40px; } }
  @media (max-width: 767px) {
    .table__cell--head {
      margin-right: 16px; } }

.image picture,
.image img {
  display: block;
  width: 100%;
  height: auto; }

.documents {
  --documents-gap: 1.25vw;
  --documents-columns: 4;
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(var(--documents-columns), calc((100% - (var(--documents-columns) - 1) * var(--documents-gap)) / var(--documents-columns)));
  grid-gap: var(--documents-gap);
  color: #000000; }
  @media (min-width: 1366px) {
    .container--narrow .documents {
      --documents-columns: 2; } }
  @media (max-width: 1365px) {
    .documents {
      --documents-gap: 24px;
      --documents-columns: 2; } }
  @media (max-width: 1023px) {
    .documents {
      --documents-columns: 1; } }
  @media (max-width: 767px) {
    .documents {
      --documents-gap: 12px; } }

.documents__item-link {
  display: flex;
  align-items: center;
  padding: 1.14583vw 1.04167vw;
  border: 1px solid #ebedef;
  border-radius: var(--card-border-radius); }
  @media (max-width: 1365px) {
    .documents__item-link {
      padding: 22px 20px; } }
  @media (max-width: 767px) {
    .documents__item-link {
      padding: 12px; } }

.documents__item-icon {
  flex-shrink: 0;
  width: 1.35417vw;
  height: 1.35417vw;
  margin-right: 0.72917vw; }
  .documents__item-icon--pdf {
    color: #f5474d; }
  .documents__item-icon--doc {
    color: #20a6ee; }
  .documents__item-icon--jpg, .documents__item-icon--png {
    color: #fa7642; }
  @media (max-width: 1365px) {
    .documents__item-icon {
      width: 26px;
      height: 26px;
      margin-right: 10px; } }
  @media (max-width: 767px) {
    .documents__item-icon {
      width: 20px;
      height: 20px; } }

.documents__item-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .documents__item-text {
      font-size: 0.78125vw; } }
  @media (max-width: 767px) {
    .documents__item-text {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: 0; } }

.social__list {
  --social-item-size: 1.25vw;
  --social-gap: 0.41667vw;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--social-gap));
  margin-left: calc(-1 * var(--social-gap)); }
  @media (max-width: 1365px) {
    .social__list {
      --social-item-size: 24px;
      --social-gap: 8px; } }

.social__item {
  margin-top: var(--social-gap);
  margin-left: var(--social-gap); }

.social__link {
  display: flex; }
  .social__link svg {
    width: var(--social-item-size);
    height: var(--social-item-size); }
  .social__link--vk {
    --link-color-interactive: #2787f5; }
  .social__link--telegram {
    --link-color-interactive: #22cce2; }

.datetime {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: #000000; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .datetime {
      font-size: 0.78125vw; } }
  @media (max-width: 767px) {
    .datetime {
      font-size: 10px;
      letter-spacing: 0; } }

.datetime__item {
  display: flex;
  align-items: center; }
  .datetime__item:not(:last-child)::after {
    content: "";
    margin: 0 8px;
    width: 2px;
    height: 2px;
    background-color: currentColor;
    border-radius: 50%; }

.pagination {
  --pagination-button-size: 1.875vw;
  --pagination-gap: 0.41667vw;
  padding-top: 2.08333vw; }
  @media (max-width: 1365px) {
    .pagination {
      --pagination-button-size: 36px;
      --pagination-gap: 8px;
      padding-top: 40px; } }
  @media (max-width: 767px) {
    .pagination {
      --pagination-button-size: 30px;
      padding-top: 20px; } }
  @media (max-width: 374px) {
    .pagination {
      --pagination-button-size: 24px; } }

.pagination__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--pagination-button-size), auto));
  grid-gap: var(--pagination-gap);
  justify-content: center; }

.pagination__button {
  --pagination-button-color-default: #acacb9;
  --pagination-button-bg-default: #f6f7f9;
  --pagination-button-border-default: #ebedef;
  --pagination-button-color-interactive: #ffffff;
  --pagination-button-bg-interactive: #161618;
  --pagination-button-border-interactive: #161618;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: var(--pagination-button-size);
  height: var(--pagination-button-size);
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--pagination-button-color-default);
  background-color: var(--pagination-button-bg-default);
  border: 1px solid var(--pagination-button-border-default);
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in; }
  .pagination__button svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .pagination__button--arrow {
    --pagination-button-color-default: inherit;
    --pagination-button-bg-default: transparent;
    --pagination-button-border-default: transparent;
    --pagination-button-color-interactive: #2787f5;
    --pagination-button-bg-interactive: transparent;
    --pagination-button-border-interactive: transparent; }
    .pagination__button--arrow.pagination__button--disabled {
      --pagination-button-color-default: #ebedef;
      --pagination-button-bg-default: transparent;
      --pagination-button-border-default: transparent;
      --pagination-button-color-interactive: #ebedef;
      --pagination-button-bg-interactive: transparent;
      --pagination-button-border-interactive: transparent;
      pointer-events: none; }
    .pagination__button--arrow svg {
      width: 0.41667vw;
      height: 0.72917vw; }
  .pagination__button--prev {
    margin-right: 1.25vw; }
  .pagination__button--next {
    margin-left: 1.25vw; }
    .pagination__button--next svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pagination__button--current {
    color: var(--pagination-button-color-interactive);
    background-color: var(--pagination-button-bg-interactive);
    border-color: var(--pagination-button-border-interactive);
    pointer-events: none; }
  .pagination__button:focus {
    color: var(--pagination-button-color-interactive);
    background-color: var(--pagination-button-bg-interactive);
    border-color: var(--pagination-button-border-interactive); }
  .pagination__button:focus:not(.focus-visible) {
    color: var(--pagination-button-color-default);
    background-color: var(--pagination-button-bg-default);
    border-color: var(--pagination-button-border-default); }
  .pagination__button:active:not(.focus-visible) {
    color: var(--pagination-button-color-interactive);
    background-color: var(--pagination-button-bg-interactive);
    border-color: var(--pagination-button-border-interactive); }
  @media (hover: hover) {
    .pagination__button:hover:not(.focus-visible) {
      color: var(--pagination-button-color-interactive);
      background-color: var(--pagination-button-bg-interactive);
      border-color: var(--pagination-button-border-interactive); } }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .pagination__button {
      font-size: 0.78125vw; } }
  @media (max-width: 1365px) {
    .pagination__button--arrow svg {
      width: 8px;
      height: 14px; }
    .pagination__button--prev {
      margin-right: 24px; }
    .pagination__button--next {
      margin-left: 24px; } }
  @media (max-width: 767px) {
    .pagination__button {
      font-weight: 500;
      font-size: 10px;
      letter-spacing: 0; }
      .pagination__button--prev {
        margin-right: 0; }
      .pagination__button--next {
        margin-left: 0; } }

.accordion {
  border-top: 1px solid #ebedef; }

.accordion__item {
  border-bottom: 1px solid #ebedef; }

.accordion__item-title {
  --accordion-item-title-color-default: #000000;
  --accordion-item-title-color-interactive: #22cce2;
  position: relative;
  padding: 1.25vw 0;
  padding-left: 1.66667vw;
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  color: var(--accordion-item-title-color-default);
  cursor: pointer;
  outline: none;
  -webkit-transition: color 0.3s ease-in;
  transition: color 0.3s ease-in; }
  .accordion__item-title::before, .accordion__item-title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.26042vw;
    width: 14px;
    height: 2px;
    background-color: var(--accordion-item-title-color-interactive);
    border-radius: var(--card-border-radius);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: -webkit-transform 0.3s ease-in;
    transition: -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in;
    transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in; }
  .accordion__item-title::before {
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg); }
  .accordion__item.is-open .accordion__item-title::before {
    -webkit-transform: translateY(-50%) rotate(0);
            transform: translateY(-50%) rotate(0); }
  .accordion__item.is-open .accordion__item-title::after {
    -webkit-transform: translateY(-50%) rotate(-90deg);
            transform: translateY(-50%) rotate(-90deg);
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s ease-in, -webkit-transform 0.3s ease-in;
    transition: visibility 0s 0.3s ease-in, -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in, visibility 0s 0.3s ease-in;
    transition: transform 0.3s ease-in, visibility 0s 0.3s ease-in, -webkit-transform 0.3s ease-in; }
  .accordion__item-title:focus {
    color: var(--accordion-item-title-color-interactive); }
  .accordion__item-title:focus:not(.focus-visible) {
    color: var(--accordion-item-title-color-default); }
  .accordion__item-title:active:not(.focus-visible) {
    color: var(--accordion-item-title-color-interactive); }
  @media (hover: hover) {
    .accordion__item-title:hover:not(.focus-visible) {
      color: var(--accordion-item-title-color-interactive); } }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .accordion__item-title {
      font-size: 1.04167vw; } }
  @media (max-width: 1365px) {
    .accordion__item-title {
      padding: 24px 0;
      padding-left: 32px; }
      .accordion__item-title::before, .accordion__item-title::after {
        left: 5px; } }
  @media (max-width: 767px) {
    .accordion__item-title {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 14px; } }

.accordion__item-content {
  height: 0;
  padding-left: 5.72917vw;
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: height 0.5s ease-in, visibility 0s 0.5s ease-in;
  transition: height 0.5s ease-in, visibility 0s 0.5s ease-in; }
  .accordion__item-content::after {
    content: "";
    display: block;
    height: 1.25vw; }
  .accordion__item.is-open .accordion__item-content {
    visibility: visible;
    -webkit-transition: height 0.5s ease-in, visibility 0s 0s;
    transition: height 0.5s ease-in, visibility 0s 0s; }
  @media (max-width: 1365px) {
    .accordion__item-content {
      padding-left: 80px; }
      .accordion__item-content::after {
        height: 24px; } }
  @media (max-width: 767px) {
    .accordion__item-content {
      padding-left: 56px; }
      .accordion__item-content::after {
        height: 16px; } }
  @media (max-width: 374px) {
    .accordion__item-content {
      padding-left: 32px; } }

.reader {
  height: calc(100 * var(--vh, 1vh));
  background-color: #f6f7f9; }
  .reader--inner {
    height: 45.3125vw;
    border: 1px solid #ebedef; }
  @media (max-width: 1365px) {
    .reader--inner {
      height: 510px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .reader--inner {
      height: 800px; } }
  @media (max-width: 767px) {
    .reader--inner {
      margin-left: calc(-1 * var(--container-padding));
      margin-right: calc(-1 * var(--container-padding)); } }

/* stylelint-disable declaration-no-important */
.map {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .map .mapboxgl-canvas-container,
  .map .mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important; }
  .map .mapboxgl-canvas:focus {
    outline-width: 2px;
    outline-color: #22cce2; }
  .map .mapboxgl-canvas:focus:not(.focus-visible) {
    outline: none; }
  .map .mapboxgl-ctrl-logo,
  .map .mapboxgl-ctrl-attrib,
  .map .mapboxgl-ctrl-icon {
    display: none !important; }
  @media (min-width: 1366px) {
    .map .mapboxgl-ctrl-bottom-right {
      bottom: 50%;
      -webkit-transform: translateY(50%);
              transform: translateY(50%); } }
  .map .mapboxgl-ctrl {
    margin-right: 2.5vw !important;
    margin-bottom: 0 !important;
    background-color: #ffffff !important;
    border-radius: var(--card-border-radius);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08) !important;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08) !important; }
    @media (max-width: 1365px) {
      .map .mapboxgl-ctrl {
        margin-right: 16px !important;
        margin-bottom: 16px !important; } }
  @media (max-width: 1365px) and (hover: none) {
    .map .mapboxgl-ctrl {
      display: none; } }
    @media (max-width: 767px) {
      .map .mapboxgl-ctrl {
        display: none; } }
  .map .mapboxgl-ctrl button {
    --map-zoom-color-default: #4c4c52;
    --map-zoom-color-interactive: #22cce2;
    position: relative;
    width: 2.91667vw;
    height: 2.91667vw;
    color: var(--map-zoom-color-default);
    background-color: transparent !important;
    border: none;
    outline: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    -webkit-transition: color 0.3s ease-in;
    transition: color 0.3s ease-in; }
    .map .mapboxgl-ctrl button::before, .map .mapboxgl-ctrl button::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: currentColor;
      border-radius: var(--card-border-radius);
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .map .mapboxgl-ctrl button::before {
      width: 18px;
      height: 2px; }
    .map .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in::after {
      width: 2px;
      height: 18px; }
    .map .mapboxgl-ctrl button:disabled {
      pointer-events: none; }
    .map .mapboxgl-ctrl button:focus {
      color: var(--map-zoom-color-interactive); }
    .map .mapboxgl-ctrl button:focus:not(.focus-visible) {
      color: var(--map-zoom-color-default); }
    .map .mapboxgl-ctrl button:active:not(.focus-visible) {
      color: var(--map-zoom-color-interactive); }
    @media (hover: hover) {
      .map .mapboxgl-ctrl button:hover:not(.focus-visible) {
        color: var(--map-zoom-color-interactive); } }
    @media (max-width: 1365px) {
      .map .mapboxgl-ctrl button {
        width: 56px;
        height: 56px; } }
    @media (max-width: 767px) {
      .map .mapboxgl-ctrl button {
        width: 48px;
        height: 48px; } }
  .map .mapboxgl-marker {
    --map-marker-size: 1.66667vw;
    --map-marker-icon-size: 1.25vw;
    --map-marker-default-color: #ffffff;
    --map-marker-default-bg: #22cce2;
    --map-marker-interactive-color: #22cce2;
    --map-marker-interactive-bg: #ffffff;
    display: grid;
    place-items: center;
    width: var(--map-marker-size);
    height: var(--map-marker-size);
    color: var(--map-marker-default-color);
    background-color: var(--map-marker-default-bg);
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.08);
    -webkit-transition: color 0.3s ease-in, background-color 0.3s ease-in;
    transition: color 0.3s ease-in, background-color 0.3s ease-in;
    outline: none;
    cursor: pointer; }
    .map .mapboxgl-marker svg {
      width: var(--map-marker-icon-size);
      height: var(--map-marker-icon-size);
      pointer-events: none; }
    .map .mapboxgl-marker:focus {
      z-index: 2;
      color: var(--map-marker-interactive-color);
      background-color: var(--map-marker-interactive-bg); }
    .map .mapboxgl-marker:focus:not(.focus-visible) {
      z-index: initial;
      color: var(--map-marker-default-color);
      background-color: var(--map-marker-default-bg); }
    .map .mapboxgl-marker:active:not(.focus-visible), .map .mapboxgl-marker.active:not(.focus-visible) {
      z-index: 2;
      color: var(--map-marker-interactive-color);
      background-color: var(--map-marker-interactive-bg); }
    @media (hover: hover) {
      .map .mapboxgl-marker:hover:not(.focus-visible) {
        z-index: 2;
        color: var(--map-marker-interactive-color);
        background-color: var(--map-marker-interactive-bg); } }
    .map .mapboxgl-marker.is-primary {
      --map-marker-size: 2.08333vw;
      --map-marker-default-color: #4c4c52;
      --map-marker-default-bg: #ffffff;
      --map-marker-interactive-color: #ffffff;
      --map-marker-interactive-bg: #4c4c52; }
    .map .mapboxgl-marker.is-disabled {
      pointer-events: none; }
    @media (max-width: 1365px) {
      .map .mapboxgl-marker {
        --map-marker-size: 32px;
        --map-marker-icon-size: 24px; }
        .map .mapboxgl-marker.is-primary {
          --map-marker-size: 40px; } }
  .map .mapboxgl-popup {
    z-index: 4;
    max-width: 13.54167vw !important;
    -webkit-filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.02)) drop-shadow(0 1px 2px rgba(0, 0, 0, 0.08));
            filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.02)) drop-shadow(0 1px 2px rgba(0, 0, 0, 0.08)); }
    @media (max-width: 1365px) {
      .map .mapboxgl-popup {
        max-width: 260px !important; } }
    @media (max-width: 767px) {
      .map .mapboxgl-popup {
        max-width: 200px !important; } }
  .map .mapboxgl-popup-content {
    padding: 1.25vw;
    text-align: center;
    color: #000000;
    background-color: #ffffff;
    border-radius: var(--card-border-radius); }
    @media (max-width: 1365px) {
      .map .mapboxgl-popup-content {
        padding: 24px; } }
  .map .mapboxgl-popup-tip {
    border: 0.83333vw solid transparent; }
    @media (max-width: 1365px) {
      .map .mapboxgl-popup-tip {
        border-width: 16px; } }
  .map .mapboxgl-popup-close-button {
    --map-popup-btn-color-default: #acacb9;
    --map-popup-btn-color-interactive: #22cce2;
    width: 1.66667vw;
    height: 1.66667vw;
    padding: 0;
    font-size: 0;
    color: var(--map-popup-btn-color-default);
    background-color: transparent !important;
    outline: none;
    -webkit-transition: color 0.3s ease-in;
    transition: color 0.3s ease-in; }
    .map .mapboxgl-popup-close-button::before, .map .mapboxgl-popup-close-button::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: currentColor;
      border-radius: var(--card-border-radius); }
    .map .mapboxgl-popup-close-button::before {
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg); }
    .map .mapboxgl-popup-close-button::after {
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg); }
    .map .mapboxgl-popup-close-button:focus {
      color: var(--map-popup-btn-color-interactive); }
    .map .mapboxgl-popup-close-button:focus:not(.focus-visible) {
      color: var(--map-popup-btn-color-default); }
    .map .mapboxgl-popup-close-button:active:not(.focus-visible) {
      color: var(--map-popup-btn-color-interactive); }
    @media (hover: hover) {
      .map .mapboxgl-popup-close-button:hover:not(.focus-visible) {
        color: var(--map-popup-btn-color-interactive); } }
    @media (max-width: 1365px) {
      .map .mapboxgl-popup-close-button {
        width: 32px;
        height: 32px; } }
  .map .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
  .map .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
  .map .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    -webkit-transform: translateY(1px);
            transform: translateY(1px); }
  .map .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px); }
  .map .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
  .map .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
  .map .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px); }
  .map .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    -webkit-transform: translateX(1px);
            transform: translateX(1px); }

.map__popup-title {
  margin: 0;
  margin-bottom: 0.41667vw;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .map__popup-title {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .map__popup-title {
      margin-bottom: 8px; } }
  @media (max-width: 767px) {
    .map__popup-title {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 1.3; } }

.map__popup-text {
  margin: 0;
  margin-bottom: 0.41667vw;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em; }
  .map__popup-text:last-child {
    margin-bottom: 0; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .map__popup-text {
      font-size: 0.67708vw; } }
  @media (max-width: 1365px) {
    .map__popup-text {
      margin-bottom: 8px; } }
  @media (max-width: 767px) {
    .map__popup-text {
      margin-bottom: 2px;
      font-size: 8px;
      line-height: 1.6;
      letter-spacing: 0; } }

.form-field {
  position: relative;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 1.6; }
  .form-field--valid {
    color: #28b839; }
  .form-field--invalid {
    color: #f5474d; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .form-field {
      padding-bottom: 1.04167vw;
      font-size: 1.04167vw; } }
  @media (max-width: 767px) {
    .form-field {
      padding-bottom: 16px;
      font-size: 14px; } }

.form-field__input {
  display: block;
  width: 100%;
  padding: 0;
  padding-right: 1.45833vw;
  padding-bottom: 0.41667vw;
  color: #000000;
  border: none;
  border-bottom: 2px solid #acacb9;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-transition: border-color 0.3s ease-in;
  transition: border-color 0.3s ease-in; }
  .form-field__input:-webkit-autofill, .form-field__input:-webkit-autofill:hover, .form-field__input:-webkit-autofill:active, .form-field__input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
            box-shadow: 0 0 0 1000px #ffffff inset; }
  .form-field__input::-webkit-input-placeholder {
    color: #4c4c52; }
  .form-field__input::-moz-placeholder {
    color: #4c4c52; }
  .form-field__input:-ms-input-placeholder {
    color: #4c4c52; }
  .form-field__input::-ms-input-placeholder {
    color: #4c4c52; }
  .form-field__input::placeholder {
    color: #4c4c52; }
  .form-field__input::-ms-clear {
    display: none; }
  .form-field__input:focus {
    border-color: #0d7ad3; }
  .form-field--valid .form-field__input {
    border-color: #28b839; }
  .form-field--invalid .form-field__input {
    border-color: #f5474d; }
  @media (max-width: 1365px) {
    .form-field__input {
      padding-right: 28px;
      padding-bottom: 8px; } }
  @media (max-width: 767px) {
    .form-field__input {
      padding-bottom: 12px; } }

.form-field__icon {
  position: absolute;
  top: 8px;
  right: 0;
  display: none;
  width: 1.04167vw;
  height: 1.04167vw; }
  .form-field--valid .form-field__icon--valid {
    display: block; }
  .form-field--invalid .form-field__icon--invalid {
    display: block; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .form-field__icon {
      top: 0.41667vw; } }
  @media (max-width: 1365px) {
    .form-field__icon {
      width: 20px;
      height: 20px; } }
  @media (max-width: 767px) {
    .form-field__icon {
      top: 4px; } }

.form-field__message {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  margin: 0;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em; }
  .form-field--valid .form-field__message--valid {
    display: block; }
  .form-field--value-missing .form-field__message--value-missing {
    display: block; }
  .form-field--pattern-mismatch .form-field__message--pattern-mismatch {
    display: block; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .form-field__message {
      font-size: 0.67708vw; } }
  @media (max-width: 767px) {
    .form-field__message {
      font-size: 10px; } }

.inner-page > .container {
  position: relative; }

@media (min-width: 1366px) {
  .inner-page .back-link {
    position: absolute;
    top: 0.41667vw;
    right: calc(100% - 2.08333vw); } }

@media (max-width: 1365px) {
  .inner-page .back-link {
    margin-bottom: 14px; } }

@media (max-width: 767px) {
  .inner-page .back-link {
    margin-bottom: 12px; } }

.inner-page__header {
  margin-bottom: 2.5vw; }
  .inner-page__header .title {
    margin-bottom: 0.83333vw; }
    .inner-page__header .title:last-child {
      margin-bottom: 0; }
  @media (max-width: 1365px) {
    .inner-page__header {
      margin-bottom: 48px; } }
  @media (max-width: 767px) {
    .inner-page__header {
      margin-bottom: 24px; }
      .inner-page__header .title {
        margin-bottom: 16px; } }

.inner-page__header-text {
  margin: 0;
  font-weight: 500;
  color: #000000; }

.page-body {
  min-width: 320px;
  font-family: "TT Norms Pro", sans-serif;
  font-size: 20px;
  line-height: 1.6;
  color: #4c4c52; }
  .page-body.fixed, .page-body.permanent-fixed {
    position: fixed;
    overflow: hidden;
    width: 100%; }
  .page-body.scroll-locked:not(.permanent-fixed) {
    padding-right: var(--scrollbar-width, 0); }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .page-body {
      font-size: 1.04167vw; } }
  @media (max-width: 767px) {
    .page-body {
      font-size: 14px; } }

.page-body__content {
  display: flex;
  flex-direction: column;
  min-height: calc(100 * var(--vh, 1vh)); }

.page-header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: 2.5vw 0;
  color: #000000;
  pointer-events: none; }
  .page-header--white {
    color: #ffffff; }
  .page-body.scroll-locked:not(.permanent-fixed) .page-header {
    padding-right: var(--scrollbar-width, 0); }
  @media (max-width: 1365px) {
    .page-header {
      padding: 48px 0; } }
  @media (max-width: 1023px) {
    .page-header {
      padding: 40px 0; } }
  @media (max-width: 767px) {
    .page-header {
      padding: 16px 0; } }

.page-header__container,
.page-header__content {
  display: flex;
  align-items: center;
  pointer-events: initial; }
  @media (min-width: 768px) and (max-width: 1365px) {
    .page-header__container,
    .page-header__content {
      align-items: flex-start; } }

.page-header__content {
  flex-grow: 1;
  margin-right: 40px; }
  @media (max-width: 1365px) {
    .page-header__content {
      flex-grow: 0;
      margin-right: auto; } }

.page-header__logo-link {
  margin-right: auto; }
  .page-header__logo-link svg {
    display: block;
    width: 7.8125vw;
    height: 2.91667vw; }
  @media (max-width: 1365px) {
    .page-header__logo-link {
      margin-right: 0; }
      .page-header__logo-link svg {
        width: 150px;
        height: 56px; } }
  @media (max-width: 767px) {
    .page-header__logo-link svg {
      width: 100px;
      height: 38px; } }

.page-header__links-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 80%;
  margin-top: -20px;
  margin-left: -40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  text-transform: uppercase; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .page-header__links-list {
      font-size: 0.9375vw; } }
  @media (max-width: 1365px) {
    .page-header__links-list {
      display: none; } }

.page-header__link-item {
  margin-top: 20px;
  margin-left: 40px; }

.page-content {
  --page-content-padding-top: 9.16667vw;
  --page-content-padding-bottom: 4.16667vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--page-content-padding-top);
  padding-bottom: var(--page-content-padding-bottom); }
  .page-content--no-padding-top {
    padding-top: 0; }
  .page-content--no-padding {
    padding-top: 0;
    padding-bottom: 0; }
  @media (max-width: 1365px) {
    .page-content {
      --page-content-padding-top: 176px;
      --page-content-padding-bottom: 80px; }
      .page-content--large-padding-top {
        --page-content-padding-top: 274px; } }
  @media (max-width: 767px) {
    .page-content {
      --page-content-padding-top: 104px;
      --page-content-padding-bottom: 40px; }
      .page-content--large-padding-top {
        --page-content-padding-top: 150px; } }

.page-footer {
  --page-footer-columns-count: 4;
  --page-footer-row-gap: 2.08333vw;
  --page-footer-column-gap: 2.08333vw;
  padding: 2.08333vw 0;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.015em;
  color: #d8d8da;
  background-color: #222225; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .page-footer {
      font-size: 0.67708vw; } }
  @media (max-width: 1365px) {
    .page-footer {
      --page-footer-row-gap: 32px;
      --page-footer-column-gap: 40px;
      padding: 40px 0; } }
  @media (max-width: 1023px) {
    .page-footer {
      --page-footer-columns-count: 3; } }
  @media (max-width: 767px) {
    .page-footer {
      --page-footer-row-gap: 16px;
      --page-footer-column-gap: 16px;
      padding: 24px 0; } }

@media (min-width: 1366px) {
  .page-footer__container {
    display: grid;
    grid-template-areas: "contacts copyright" "contacts links";
    grid-template-columns: 1fr 15.625vw;
    grid-template-rows: auto 1fr;
    grid-column-gap: var(--page-footer-column-gap); } }

.page-footer__contacts {
  grid-area: contacts;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--page-footer-row-gap));
  margin-left: calc(-1 * var(--page-footer-column-gap)); }
  @media (max-width: 1023px) {
    .page-footer__contacts {
      flex-wrap: wrap; } }

.page-footer__contacts-section {
  max-width: calc(100% / var(--page-footer-columns-count) - var(--page-footer-row-gap));
  margin-top: var(--page-footer-row-gap);
  margin-left: var(--page-footer-column-gap); }
  .page-footer__contacts-section--separated {
    flex-shrink: 0;
    max-width: 100%;
    padding-right: var(--page-footer-column-gap);
    border-right: 1px solid #53585f; }
  @media (max-width: 1023px) {
    .page-footer__contacts-section {
      max-width: 100%; }
      .page-footer__contacts-section--separated {
        flex-shrink: initial;
        width: 100%;
        padding-right: 0;
        padding-bottom: var(--page-footer-row-gap);
        border-right: none;
        border-bottom: 1px solid #53585f; } }

.page-footer__contacts-title {
  margin: 0;
  font-weight: 500;
  font-size: inherit;
  color: #ffffff; }

.page-footer__contacts-text {
  margin: 0;
  margin-top: 0.20833vw; }
  .page-footer__contacts-text--big {
    margin-top: 0.41667vw;
    font-family: "TT Travels", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    color: #ffffff; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .page-footer__contacts-text--big {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .page-footer__contacts-text {
      margin-top: 4px; }
      .page-footer__contacts-text--big {
        margin-top: 8px; } }
  @media (max-width: 767px) {
    .page-footer__contacts-text--big {
      margin-top: 6px;
      font-size: 16px;
      line-height: 1.3; } }

.page-footer__copyright,
.page-footer__links {
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em; }
  @media (min-width: 1366px) {
    .page-footer__copyright,
    .page-footer__links {
      text-align: right; } }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .page-footer__copyright,
    .page-footer__links {
      font-size: 0.78125vw; } }
  @media (max-width: 767px) {
    .page-footer__copyright,
    .page-footer__links {
      font-size: inherit;
      line-height: inherit; } }

.page-footer__copyright {
  grid-area: copyright;
  margin: 0;
  font-weight: 500;
  color: #ffffff; }
  @media (max-width: 1365px) {
    .page-footer__copyright {
      margin-top: var(--page-footer-row-gap); } }
  @media (max-width: 1023px) {
    .page-footer__copyright {
      padding-top: var(--page-footer-row-gap);
      border-top: 1px solid #53585f; } }

.page-footer__links {
  padding: 0;
  margin: 0;
  list-style: none;
  grid-area: links;
  margin-top: 0.20833vw; }
  @media (max-width: 1365px) {
    .page-footer__links {
      margin-top: 4px; } }

.menu {
  --menu-gap: 1.97917vw; }
  @media (max-width: 1365px) {
    .menu {
      --menu-gap: 32px; } }
  @media (max-width: 767px) {
    .menu {
      --menu-gap: 24px; } }

.menu__button {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  position: relative;
  position: relative;
  z-index: 20;
  display: block;
  width: 2.08333vw;
  height: 1.45833vw; }
  .menu__button svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .menu__button:focus {
    opacity: 0.5; }
  .menu__button:focus:not(.focus-visible) {
    opacity: 1; }
  .menu__button:active:not(.focus-visible) {
    opacity: 0.7; }
  @media (hover: hover) {
    .menu__button:hover:not(.focus-visible) {
      opacity: 0.8; } }
  .menu__button::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 4.16667vw;
    height: 4.16667vw;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 1365px) {
    .menu__button {
      position: relative;
      width: 40px;
      height: 28px; }
      .menu__button::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }
  @media (max-width: 767px) {
    .menu__button {
      width: 32px;
      height: 21px; } }

.menu__button-line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.20833vw;
  background-color: #22cce2;
  -webkit-transition-property: top, bottom, background-color, opacity, -webkit-transform;
  transition-property: top, bottom, background-color, opacity, -webkit-transform;
  transition-property: top, bottom, background-color, transform, opacity;
  transition-property: top, bottom, background-color, transform, opacity, -webkit-transform;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s; }
  .menu__button-line--top {
    top: 0; }
  .menu__button-line--center {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .menu__button-line--bottom {
    bottom: 0; }
  .menu__button--open .menu__button-line {
    background-color: #ffffff; }
    .menu__button--open .menu__button-line--top {
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(45deg);
              transform: translateY(-50%) rotate(45deg); }
    .menu__button--open .menu__button-line--center {
      opacity: 0; }
    .menu__button--open .menu__button-line--bottom {
      bottom: 50%;
      -webkit-transform: translateY(50%) rotate(-45deg);
              transform: translateY(50%) rotate(-45deg); }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .menu__button-line {
      height: 3px; } }
  @media (max-width: 1365px) {
    .menu__button-line {
      width: 40px;
      height: 4px; } }
  @media (max-width: 767px) {
    .menu__button-line {
      width: 32px;
      height: 3px; } }

.menu__content-wrapper {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh, 1vh));
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.5s ease-in;
  transition: visibility 0s 0.5s ease-in;
  pointer-events: none; }
  .menu__content-wrapper--shown {
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    transition: visibility 0s 0s;
    pointer-events: initial; }

.menu__content {
  position: absolute;
  top: 0;
  left: calc(100% - var(--scrollbar-width, 0));
  width: 26.82292vw;
  height: 100%;
  padding-top: 7.91667vw;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.005em;
  color: #d8d8da;
  background-color: #161618; }
  .menu__content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: var(--scrollbar-width, 0);
    height: 100%;
    background-color: inherit; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .menu__content {
      font-size: 1.66667vw; } }
  @media (max-width: 1365px) {
    .menu__content {
      width: 515px;
      padding-top: 124px; } }
  @media (max-width: 767px) {
    .menu__content {
      width: 100%;
      padding-top: 70px;
      font-weight: 700;
      font-size: 21px;
      line-height: 1.1;
      letter-spacing: 0; } }

.menu__container {
  max-height: 100%;
  overflow: auto; }
  @media (max-width: 767px) {
    .menu__container {
      padding-left: 40px;
      padding-right: 40px; } }

.menu__list {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: var(--menu-gap) 0;
  border-bottom: 1px solid #53585f; }
  .menu__list:first-child {
    padding-top: 0; }
  .menu__list:last-child {
    border-bottom: none; }

.menu__list-item {
  margin-bottom: var(--menu-gap); }
  .menu__list-item:last-child {
    margin-bottom: 0; }

.menu__list-link {
  display: flex;
  align-items: center; }
  .menu__list-link svg {
    width: 1.04167vw;
    height: 1.04167vw;
    margin-left: 0.52083vw;
    margin-bottom: 4px; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .menu__list-link {
      margin-bottom: 0.20833vw; } }
  @media (max-width: 1365px) {
    .menu__list-link svg {
      width: 20px;
      height: 20px;
      margin-left: 10px; } }

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100 * var(--vh, 1vh));
  padding-top: 7.91667vw;
  padding-bottom: 6.25vw;
  font-family: "TT Travels", sans-serif;
  color: #ffffff; }
  @media (max-width: 1365px) {
    .intro {
      padding-top: 152px;
      padding-bottom: 48px; } }
  @media (max-width: 1023px) {
    .intro {
      padding-top: 136px; } }
  @media (max-width: 767px) {
    .intro {
      padding-top: 70px;
      padding-bottom: calc(5 * var(--vh, 1vh)); } }

.intro__video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover; }
  .intro__video::after,
  .intro__video video {
    width: 100%;
    height: 100%; }
  .intro__video::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45); }
  .intro__video video {
    display: block;
    -o-object-fit: cover;
       object-fit: cover; }

.intro__container {
  display: grid;
  grid-template-areas: "title contacts" "buttons contacts";
  grid-template-columns: 1fr 18.75vw;
  grid-template-rows: auto 1fr;
  grid-column-gap: 6.25vw; }
  @media (max-width: 1365px) {
    .intro__container {
      display: flex;
      flex-direction: column; } }

@media (max-width: 1023px) and (orientation: landscape) {
  .intro__container {
    height: 100%;
    align-items: center;
    text-align: center; } }
  @media (max-width: 767px) {
    .intro__container {
      height: 100%;
      align-items: center;
      text-align: center; } }

.intro__title {
  grid-area: title;
  margin: 0;
  margin-bottom: 2.5vw;
  font-weight: 700;
  font-size: 70px;
  line-height: 1.1; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .intro__title {
      font-size: 3.64583vw; } }
  @media (max-width: 1365px) {
    .intro__title {
      margin-bottom: 48px;
      font-size: 62px; } }

@media (max-width: 1023px) and (orientation: landscape) {
  .intro__title {
    margin-top: auto;
    margin-bottom: calc(5 * var(--vh, 1vh));
    font-size: 32px; } }
  @media (max-width: 767px) {
    .intro__title {
      margin-top: auto;
      margin-bottom: calc(5 * var(--vh, 1vh));
      font-size: 32px; } }

@media (max-width: 1365px) {
  .intro__buttons-wrapper {
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); } }

@media (max-width: 767px) {
  .intro__buttons-wrapper {
    width: 100%;
    max-width: 400px;
    padding-bottom: 0;
    margin: 0 auto calc(5 * var(--vh, 1vh));
    border-bottom: none; } }

.intro__buttons {
  grid-area: buttons; }
  @media (min-width: 768px) {
    .intro__buttons {
      --intro-buttons-gap: 0.83333vw;
      display: flex;
      margin-top: calc(-1 * var(--intro-buttons-gap));
      margin-left: calc(-1 * var(--intro-buttons-gap)); } }
  @media (max-width: 1365px) {
    .intro__buttons {
      --intro-buttons-gap: 16px; } }

@media (max-width: 1023px) and (orientation: landscape) {
  .intro__buttons {
    display: none; } }
  @media (max-width: 767px) {
    .intro__buttons {
      margin: 0; } }

.intro__button {
  margin-top: var(--intro-buttons-gap);
  margin-left: var(--intro-buttons-gap); }
  @media (max-width: 767px) {
    .intro__button {
      justify-content: center;
      width: 100%;
      margin: 0;
      margin-bottom: 16px; }
      .intro__button:last-child {
        margin-bottom: 0; } }

.intro__contacts {
  padding: 0;
  margin: 0;
  list-style: none;
  grid-area: contacts;
  align-self: end;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .intro__contacts {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .intro__contacts {
      display: flex;
      flex-wrap: wrap;
      margin-top: -40px;
      margin-left: -40px; } }

@media (max-width: 1023px) and (orientation: landscape) {
  .intro__contacts {
    align-self: center; } }
  @media (max-width: 767px) {
    .intro__contacts {
      align-self: center;
      display: block;
      margin: 0;
      margin-top: auto;
      font-size: 16px;
      line-height: 1.3; } }

.intro__contact-item {
  margin-bottom: 0.52083vw; }
  .intro__contact-item:last-child {
    margin-bottom: 0; }
  @media (max-width: 1365px) {
    .intro__contact-item {
      margin-top: 40px;
      margin-left: 40px;
      margin-bottom: 0; } }
  @media (max-width: 767px) {
    .intro__contact-item {
      margin: 0;
      margin-bottom: 10px; } }

.intro__contact-link {
  display: inline-flex;
  align-items: center; }
  .intro__contact-link svg {
    width: 26px;
    height: 26px;
    margin-right: 14px; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .intro__contact-link svg {
      width: 1.35417vw;
      height: 1.35417vw;
      margin-right: 0.72917vw; } }
  @media (max-width: 767px) {
    .intro__contact-link svg {
      width: 20px;
      height: 20px; } }

.objects__list {
  --objects-gap: 2.08333vw;
  --objects-columns: 4;
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(var(--objects-columns), 1fr);
  grid-gap: var(--objects-gap); }
  @media (max-width: 1365px) {
    .objects__list {
      --objects-gap: 40px;
      --objects-columns: 2; } }
  @media (max-width: 1023px) {
    .objects__list {
      --objects-columns: 1; } }
  @media (max-width: 767px) {
    .objects__list {
      --objects-gap: 16px; } }

.objects__item {
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #d8d8da;
  border-radius: var(--card-border-radius);
  overflow: hidden; }
  .objects__item::before {
    content: "";
    display: block;
    padding-bottom: 74%; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .objects__item {
      font-size: 0.67708vw; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .objects__item::before {
      padding-bottom: 54%; } }
  @media (max-width: 767px) {
    .objects__item {
      font-size: 8px;
      line-height: 1.6;
      letter-spacing: 0; } }

.objects__item-link {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 1.25vw;
  user-select: none; }
  .objects__item-link svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  @media (max-width: 1365px) {
    .objects__item-link {
      padding: 24px; } }
  @media (max-width: 767px) {
    .objects__item-link {
      padding: 16px; } }

.objects__item-title {
  margin: 0;
  margin-bottom: 0.83333vw;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.005em;
  color: #ffffff; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .objects__item-title {
      font-size: 1.66667vw; } }
  @media (max-width: 1365px) {
    .objects__item-title {
      margin-bottom: 16px; } }
  @media (max-width: 767px) {
    .objects__item-title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 21px;
      line-height: 1.1;
      letter-spacing: 0; } }

.objects__item-address {
  margin: 0;
  margin-bottom: 0.20833vw; }
  @media (max-width: 1365px) {
    .objects__item-address {
      margin-bottom: 4px; } }
  @media (max-width: 767px) {
    .objects__item-address {
      margin-bottom: 0; } }

.objects__item-year {
  margin: 0; }

.objects__item-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .objects__item-img img, .objects__item-img::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .objects__item-img img {
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: -webkit-transform 0.3s ease-in;
    transition: -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in;
    transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
    will-change: transform; }
    .objects__item-link:focus .objects__item-img img {
      -webkit-transform: translate(-50%, -50%) scale(1.1);
              transform: translate(-50%, -50%) scale(1.1); }
    .objects__item-link:focus:not(.focus-visible) .objects__item-img img {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1); }
    .objects__item-link:active:not(.focus-visible) .objects__item-img img {
      -webkit-transform: translate(-50%, -50%) scale(1.1);
              transform: translate(-50%, -50%) scale(1.1); }
    @media (hover: hover) {
      .objects__item-link:hover:not(.focus-visible) .objects__item-img img {
        -webkit-transform: translate(-50%, -50%) scale(1.1);
                transform: translate(-50%, -50%) scale(1.1); } }
  .objects__item-img::after {
    content: "";
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); }

.services__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  @media (max-width: 767px) {
    .services__header {
      display: block; } }

.services .title {
  margin-right: auto;
  margin-bottom: 0; }
  @media (max-width: 1365px) {
    .services .title {
      width: 100%;
      margin-bottom: 24px;
      margin-left: 0; } }
  @media (max-width: 767px) {
    .services .title {
      text-align: center; } }

.services__button {
  margin-left: 16px; }
  @media (max-width: 1365px) {
    .services__button {
      margin-right: 16px;
      margin-left: 0; } }
  @media (max-width: 767px) {
    .services__button {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 400px;
      margin: 0 auto 16px; }
      .services__button:last-child {
        margin-bottom: 0; } }

.services__list {
  padding: 0;
  margin: 0;
  list-style: none;
  --services-gap: 2.08333vw;
  --services-columns: 4;
  display: grid;
  grid-template-columns: repeat(var(--services-columns), 1fr);
  grid-gap: var(--services-gap);
  margin-bottom: 2.08333vw; }
  @media (max-width: 1365px) {
    .services__list {
      --services-gap: 40px;
      --services-columns: 2;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .services__list {
      --services-gap: 16px;
      grid-template-columns: 100%;
      margin-bottom: 16px; } }

.services__item-link {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25vw;
  border: 2px solid #ebedef;
  border-radius: var(--card-border-radius); }
  .services__item-link svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .services__item-link:focus {
    opacity: 0.5; }
  .services__item-link:focus:not(.focus-visible) {
    opacity: 1; }
  .services__item-link:active:not(.focus-visible) {
    opacity: 0.7; }
  @media (hover: hover) {
    .services__item-link:hover:not(.focus-visible) {
      opacity: 0.8; } }
  @media (max-width: 1365px) {
    .services__item-link {
      padding: 24px; } }
  @media (max-width: 767px) {
    .services__item-link {
      display: grid;
      grid-template-areas: "icon title" "icon count";
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 1fr;
      padding: 16px; } }

.services__item-icon {
  display: grid;
  place-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background-color: #f6f7f9;
  border-radius: 50%; }
  .services__item-icon svg {
    width: 48px;
    height: 48px; }
  @media (max-width: 1365px) {
    .services__item-icon {
      width: 80px;
      height: 80px;
      margin-bottom: 20px; }
      .services__item-icon svg {
        width: 48px;
        height: 48px; } }
  @media (max-width: 767px) {
    .services__item-icon {
      grid-area: icon;
      width: 64px;
      height: 64px;
      margin-right: 16px;
      margin-bottom: 0; }
      .services__item-icon svg {
        width: 38px;
        height: 38px; } }

.services__item-title {
  margin-top: auto;
  margin-bottom: 0.625vw;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: #000000; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .services__item-title {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .services__item-title {
      margin-bottom: 12px; } }
  @media (max-width: 767px) {
    .services__item-title {
      grid-area: title;
      font-size: 16px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .services__item-title {
      font-size: 14px; } }

.services__item-count {
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: #22cce2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .services__item-count {
      font-size: 0.67708vw; } }
  @media (max-width: 767px) {
    .services__item-count {
      grid-area: count;
      font-size: 8px;
      line-height: 1.6;
      letter-spacing: 0; } }

.service-page__table,
.service-page__table-head,
.service-page__table-body,
.service-page__table-row,
.service-page__table-cell,
.service-page__subtable,
.service-page__subtable-caption,
.service-page__subtable-body {
  display: block; }

.service-page__table {
  margin-bottom: 2.08333vw;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .service-page__table {
      font-size: 0.78125vw; } }
  @media (max-width: 1365px) {
    .service-page__table {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .service-page__table {
      margin-bottom: 24px;
      font-size: 10px; } }

.service-page__table-head {
  padding-bottom: 1.25vw;
  font-weight: 500;
  color: #000000; }
  @media (max-width: 1365px) {
    .service-page__table-head {
      padding-bottom: 24px; } }
  @media (max-width: 767px) {
    .service-page__table-head {
      padding-bottom: 16px; } }

.service-page__table-row--inner {
  display: grid;
  grid-template-columns: 1fr repeat(2, 10.67708vw);
  grid-column-gap: 2.08333vw;
  padding-left: 1.66667vw;
  margin-bottom: 0.83333vw; }
  .service-page__table-row--inner:last-child {
    margin-bottom: 0; }

@media (max-width: 1365px) {
  .service-page__table-row--inner {
    grid-template-columns: 1fr repeat(2, 95px);
    grid-column-gap: 40px;
    padding-left: 32px;
    margin-bottom: 16px; } }

@media (max-width: 767px) {
  .service-page__table-row--inner {
    grid-template-columns: 1fr repeat(2, 55px);
    grid-column-gap: 32px;
    margin-bottom: 12px; } }

.service-page__table-cell {
  padding: 0;
  font-weight: 400;
  text-align: right; }
  .service-page__table-cell:first-child {
    font-weight: 500;
    text-align: left; }

.service-page__subtable-caption {
  text-align: left; }

.service-page__subtable-body {
  padding: 0; }

.service-page__button {
  margin-bottom: 2.08333vw; }
  @media (max-width: 1365px) {
    .service-page__button {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .service-page__button {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 400px;
      margin: 0 auto 24px; } }

.payment__features {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  color: #000000; }

.payment__feature {
  padding-right: 0.83333vw;
  margin-right: 0.83333vw;
  border-right: 1px solid #ebedef; }
  .payment__feature:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none; }
  @media (max-width: 1365px) {
    .payment__feature {
      padding-right: 16px;
      margin-right: 16px; } }

.payment__options {
  --payment-options-column-width: 21.35417vw;
  --payment-options-gap: 2.08333vw;
  --payment-options-columns-count: 2;
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(var(--payment-options-columns-count), var(--payment-options-column-width));
  grid-gap: var(--payment-options-gap); }
  @media (max-width: 1365px) {
    .payment__options {
      --payment-options-column-width: 410px;
      --payment-options-gap: 40px; } }
  @media (max-width: 1023px) {
    .payment__options {
      --payment-options-gap: 24px;
      --payment-options-columns-count: 1; } }
  @media (max-width: 767px) {
    .payment__options {
      --payment-options-column-width: 100%;
      --payment-options-gap: 16px; } }

.payment__option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.66667vw;
  color: #ffffff;
  background-color: #22cce2;
  border-radius: var(--card-border-radius);
  overflow: hidden; }
  .payment__option::before, .payment__option::after {
    content: "";
    position: absolute;
    width: 11.45833vw;
    height: 13.02083vw;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
  .payment__option::before {
    background-color: rgba(12, 233, 225, 0.3); }
  .payment__option::after {
    background-color: #32e7e4;
    -webkit-box-shadow: 0 1.45833vw 3.69792vw rgba(0, 0, 0, 0.04), 0 0.52083vw 1.14583vw rgba(0, 0, 0, 0.05), 0 0.10417vw 0.3125vw rgba(0, 0, 0, 0.05);
            box-shadow: 0 1.45833vw 3.69792vw rgba(0, 0, 0, 0.04), 0 0.52083vw 1.14583vw rgba(0, 0, 0, 0.05), 0 0.10417vw 0.3125vw rgba(0, 0, 0, 0.05); }
  .payment__option:nth-child(4n+1)::before {
    right: -3.33333vw;
    bottom: -5.9375vw; }
  .payment__option:nth-child(4n+1)::after {
    right: -4.16667vw;
    bottom: -8.02083vw; }
  .payment__option:nth-child(4n+2)::before {
    bottom: -7.60417vw;
    left: -7.08333vw; }
  .payment__option:nth-child(4n+2)::after {
    bottom: -10.3125vw;
    left: -5.10417vw; }
  .payment__option:nth-child(4n+3)::before {
    top: -5.72917vw;
    right: -4.79167vw;
    width: 14.58333vw; }
  .payment__option:nth-child(4n+3)::after {
    right: -4.16667vw;
    top: -7.08333vw; }
  .payment__option:nth-child(4n+4)::before {
    top: -7.1875vw;
    left: -7.08333vw; }
  .payment__option:nth-child(4n+4)::after {
    top: -4.79167vw;
    left: -5.10417vw; }
  @media (max-width: 1365px) {
    .payment__option {
      padding: 32px; }
      .payment__option::before, .payment__option::after {
        width: 220px;
        height: 250px; }
      .payment__option::after {
        -webkit-box-shadow: 0 28px 71px rgba(0, 0, 0, 0.04), 0 10px 22px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05);
                box-shadow: 0 28px 71px rgba(0, 0, 0, 0.04), 0 10px 22px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05); }
      .payment__option:nth-child(4n+1)::before {
        right: -64px;
        bottom: -114px; }
      .payment__option:nth-child(4n+1)::after {
        right: -80px;
        bottom: -154px; }
      .payment__option:nth-child(4n+2)::before {
        bottom: -146px;
        left: -136px; }
      .payment__option:nth-child(4n+2)::after {
        bottom: -198px;
        left: -98px; }
      .payment__option:nth-child(4n+3)::before {
        top: -110px;
        right: -92px;
        width: 280px; }
      .payment__option:nth-child(4n+3)::after {
        right: -80px;
        top: -136px; }
      .payment__option:nth-child(4n+4)::before {
        top: -138px;
        left: -136px; }
      .payment__option:nth-child(4n+4)::after {
        top: -92px;
        left: -98px; } }
  @media (max-width: 1023px) {
    .payment__option {
      min-height: 212px; }
      .payment__option:nth-child(4n+1)::before {
        right: -64px;
        bottom: -114px; }
      .payment__option:nth-child(4n+1)::after {
        right: -80px;
        bottom: -154px; }
      .payment__option:nth-child(4n+2)::before {
        top: -124px;
        right: -48px;
        bottom: initial;
        left: initial; }
      .payment__option:nth-child(4n+2)::after {
        top: -136px;
        right: -90px;
        bottom: initial;
        left: initial; }
      .payment__option:nth-child(4n+3)::before {
        top: initial;
        right: initial;
        bottom: -170px;
        left: -115px; }
      .payment__option:nth-child(4n+3)::after {
        top: initial;
        right: initial;
        bottom: -198px;
        left: -98px; }
      .payment__option:nth-child(4n+4)::before {
        top: -110px;
        left: -56px; }
      .payment__option:nth-child(4n+4)::after {
        top: -92px;
        left: -98px; } }
  @media (max-width: 767px) {
    .payment__option {
      min-height: 150px;
      padding: 24px; }
      .payment__option:nth-child(4n+1)::before {
        right: -84px;
        bottom: -174px; }
      .payment__option:nth-child(4n+1)::after {
        right: -98px;
        bottom: -215px; }
      .payment__option:nth-child(4n+2)::before {
        top: -134px;
        right: -110px; }
      .payment__option:nth-child(4n+2)::after {
        top: -145px;
        right: -155px; }
      .payment__option:nth-child(4n+3)::before {
        bottom: -195px; }
      .payment__option:nth-child(4n+3)::after {
        bottom: -218px; }
      .payment__option:nth-child(4n+4)::before {
        top: -140px;
        left: -84px; }
      .payment__option:nth-child(4n+4)::after {
        top: -178px;
        left: -94px; } }

.payment__option-title,
.payment__option-button {
  position: relative;
  z-index: 2; }

.payment__option-title {
  margin: 0;
  margin-bottom: 24px;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .payment__option-title {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .payment__option-title {
      margin-bottom: 24px; } }
  @media (max-width: 767px) {
    .payment__option-title {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 1.3; } }

.payment__option-button {
  display: flex;
  align-items: center;
  margin-top: auto;
  -webkit-box-shadow: 0 0.26042vw 0.52083vw rgba(0, 0, 0, 0.07), 0 0.05208vw 0.10417vw rgba(0, 0, 0, 0.12);
          box-shadow: 0 0.26042vw 0.52083vw rgba(0, 0, 0, 0.07), 0 0.05208vw 0.10417vw rgba(0, 0, 0, 0.12); }
  @media (max-width: 1365px) {
    .payment__option-button {
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.12);
              box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.12); } }

.error-page {
  overflow: hidden;
  margin: auto 0; }

.error-page__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: "TT Travels", sans-serif;
  color: #000000; }
  @media (max-width: 1023px) {
    .error-page__container {
      flex-direction: column;
      align-items: flex-start; } }

.error-page__content {
  flex-shrink: 0;
  width: 14.84375vw;
  padding-bottom: 3.85417vw; }
  @media (max-width: 1365px) {
    .error-page__content {
      width: 285px;
      padding-bottom: 10px; } }
  @media (max-width: 1023px) {
    .error-page__content {
      margin-top: -44px;
      padding-bottom: 0; } }
  @media (max-width: 767px) {
    .error-page__content {
      width: 190px;
      margin-top: -20px; } }

.error-page__title {
  margin: 0;
  font-weight: 700;
  font-size: 128px;
  line-height: 1.1; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .error-page__title {
      font-size: 6.66667vw; } }
  @media (max-width: 767px) {
    .error-page__title {
      font-size: 80px; } }

.error-page__text {
  margin: 0;
  margin-bottom: 1.66667vw;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .error-page__text {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .error-page__text {
      margin-bottom: 32px; } }
  @media (max-width: 767px) {
    .error-page__text {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 1.3; } }

.error-page__image {
  width: 47.13542vw;
  height: auto; }
  @media (max-width: 1365px) {
    .error-page__image {
      width: 635px;
      -webkit-transform: translateX(10px);
              transform: translateX(10px); } }
  @media (max-width: 1023px) {
    .error-page__image {
      order: -1;
      width: 100%;
      -webkit-transform: translateX(25px);
              transform: translateX(25px); } }
  @media (max-width: 767px) {
    .error-page__image {
      width: 106%;
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px); } }

.object-intro {
  --object-intro-column-gap: 2.08333vw;
  --object-intro-row-gap: 0.83333vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 20.57292vw;
  padding-top: 10.41667vw;
  padding-bottom: 2.08333vw;
  color: #ffffff; }
  @media (max-width: 1365px) {
    .object-intro {
      --object-intro-column-gap: 40px;
      --object-intro-row-gap: 16px;
      min-height: 375px;
      padding-top: 200px;
      padding-bottom: 36px; } }
  @media (max-width: 1023px) {
    .object-intro {
      min-height: 425px;
      padding-top: 176px; } }
  @media (max-width: 767px) {
    .object-intro {
      --object-intro-column-gap: 32px;
      min-height: 320px;
      padding-top: 86px;
      padding-bottom: 28px; } }

.object-intro__container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between; }

.object-intro__title {
  margin-right: calc(2 * var(--object-intro-column-gap));
  color: inherit; }
  @media (max-width: 1365px) {
    .object-intro__title {
      width: 100%;
      margin-right: 0;
      font-size: 58px; } }
  @media (max-width: 767px) {
    .object-intro__title {
      font-size: 28px; } }
  @media (max-width: 374px) {
    .object-intro__title {
      font-size: 24px; } }

.object-intro__contacts {
  margin-top: 1.25vw; }
  @media (max-width: 1365px) {
    .object-intro__contacts {
      margin-top: 24px; } }

.object-intro__contacts-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--object-intro-row-gap));
  margin-left: calc(-1 * var(--object-intro-column-gap)); }

.object-intro__contact {
  margin-top: var(--object-intro-row-gap);
  margin-left: var(--object-intro-column-gap); }
  @media (max-width: 767px) {
    .object-intro__contact {
      min-width: 94px; } }

.object-intro__contact-title {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .object-intro__contact-title {
      font-size: 0.67708vw; } }

.object-intro__contact-link {
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .object-intro__contact-link {
      font-size: 1.25vw; } }
  @media (max-width: 767px) {
    .object-intro__contact-link {
      font-size: 16px;
      line-height: 1.3; } }

.object-intro__social {
  flex-wrap: nowrap;
  margin-top: 0.20833vw; }
  @media (max-width: 1365px) {
    .object-intro__social {
      margin-top: 4px; } }

.object-intro__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .object-intro__bg img, .object-intro__bg::after {
    width: 100%;
    height: 100%; }
  .object-intro__bg img {
    display: block;
    -o-object-fit: cover;
       object-fit: cover; }
  .object-intro__bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7); }

.object-nav {
  scrollbar-width: none;
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  overflow-x: auto;
  padding-top: 2.08333vw;
  color: #acacb9;
  background-color: #ffffff; }
  .object-nav::-webkit-scrollbar {
    display: none; }
  @media (max-width: 1365px) {
    .object-nav {
      padding-top: 40px; } }
  @media (max-width: 767px) {
    .object-nav {
      padding-top: 20px; } }

.object-nav__container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%; }

.object-nav__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  padding-bottom: 1.66667vw;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  white-space: nowrap;
  border-bottom: 1px solid #ebedef; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .object-nav__list {
      font-size: 1.04167vw; } }
  @media (max-width: 1365px) {
    .object-nav__list {
      padding-bottom: 32px; } }
  @media (max-width: 767px) {
    .object-nav__list {
      padding-bottom: 16px;
      font-size: 14px; } }

.object-nav__item {
  margin-right: 32px; }
  .object-nav__item:last-child {
    margin-right: 0; }
  @media (max-width: 1365px) {
    .object-nav__item {
      margin-right: 32px; } }
  @media (max-width: 767px) {
    .object-nav__item {
      margin-right: 16px; } }

.object-nav__link--current {
  color: var(--link-color-interactive); }

.object-content {
  padding-top: 2.08333vw; }
  @media (max-width: 1365px) {
    .object-content {
      padding-top: 40px; } }
  @media (max-width: 767px) {
    .object-content {
      padding-top: 20px; } }

/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
.news__list {
  --news-gap: 2.08333vw;
  --news-columns: 4;
  display: grid;
  grid-template-columns: repeat(var(--news-columns), 1fr);
  grid-gap: var(--news-gap); }
  @media (max-width: 1365px) {
    .news__list {
      --news-gap: 40px;
      --news-columns: 2; } }
  @media (max-width: 1023px) {
    .news__list {
      --news-gap: 24px;
      grid-template-columns: 100%; } }
  @media (max-width: 767px) {
    .news__list {
      --news-gap: 16px; } }

.news__card {
  min-height: 13.33333vw;
  background-color: #f6f7f9;
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column; }
  @media (max-width: 1365px) {
    .news__card {
      min-height: 256px; } }
  @media (max-width: 1023px) {
    .news__card {
      min-height: 236px; } }
  @media (max-width: 767px) {
    .news__card {
      min-height: 158px; } }

.news__card-link {
  --link-color-default: #000000;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25vw;
  flex-grow: 1; }
  @media (max-width: 1365px) {
    .news__card-link {
      padding: 24px; } }
  @media (max-width: 767px) {
    .news__card-link {
      padding: 16px; } }

.news__card-title,
.news__card-text {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

.news__card-title {
  margin-bottom: 0.83333vw;
  font-family: "TT Travels", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  -webkit-line-clamp: 2; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .news__card-title {
      font-size: 1.25vw; } }
  @media (max-width: 1365px) {
    .news__card-title {
      margin-bottom: 16px; } }
  @media (max-width: 767px) {
    .news__card-title {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 1.3; } }

.news__card-text {
  margin-bottom: 1.66667vw;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: #4c4c52;
  -webkit-line-clamp: 4; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .news__card-text {
      font-size: 0.78125vw; } }
  @media (max-width: 1365px) {
    .news__card-text {
      margin-bottom: 32px; } }
  @media (max-width: 1023px) {
    .news__card-text {
      -webkit-line-clamp: 3; } }
  @media (max-width: 767px) {
    .news__card-text {
      margin-bottom: 12px;
      font-size: 12px; } }

.news__card-datetime {
  margin-top: auto; }

.addresses__map-wrapper {
  position: relative;
  margin-bottom: 2.08333vw; }
  @media (max-width: 1365px) {
    .addresses__map-wrapper {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .addresses__map-wrapper {
      margin-bottom: 20px; } }

.addresses__map-container {
  height: 40.36458vw; }
  @media (max-width: 1365px) {
    .addresses__map-container {
      height: 510px; } }
  @media (max-width: 1023px) {
    .addresses__map-container {
      height: 800px; } }
  @media (max-width: 767px) {
    .addresses__map-container {
      margin-left: calc(-1 * var(--container-padding));
      margin-right: calc(-1 * var(--container-padding));
      height: 430px; } }

.addresses__filter {
  --addresses-filter-gap: 2.08333vw;
  position: absolute;
  z-index: 2;
  left: 0.83333vw;
  bottom: 0.83333vw;
  width: 23.4375vw;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.16);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: var(--card-border-radius); }
  @media (max-width: 1365px) {
    .addresses__filter {
      --addresses-filter-gap: 40px;
      left: 16px;
      bottom: 16px;
      width: 450px; } }
  @media (max-width: 767px) {
    .addresses__filter {
      margin-left: calc(-1 * var(--container-padding));
      margin-right: calc(-1 * var(--container-padding));
      position: sticky;
      left: initial;
      bottom: 0;
      width: auto;
      padding: 16px 0;
      background-color: #f6f7f9;
      -webkit-box-shadow: none;
              box-shadow: none;
      border-radius: 0; } }

.addresses__filter-title {
  --addresses-filter-title-default-color: #000000;
  --addresses-filter-title-interactive-color: #22cce2;
  display: flex;
  align-items: center;
  padding: 1.66667vw var(--addresses-filter-gap);
  margin: 0;
  color: var(--addresses-filter-title-default-color);
  outline: none;
  -webkit-transition: color 0.3s ease-in;
  transition: color 0.3s ease-in;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }
  .addresses__filter-title svg {
    width: 0.41667vw;
    height: 0.72917vw;
    margin-left: 0.72917vw;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.3s ease-in;
    transition: -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in;
    transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in; }
  .addresses__filter.is-opened .addresses__filter-title svg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .addresses__filter-title:focus {
    color: var(--addresses-filter-title-interactive-color); }
  .addresses__filter-title:focus:not(.focus-visible) {
    color: var(--addresses-filter-title-default-color); }
  .addresses__filter-title:active:not(.focus-visible) {
    color: var(--addresses-filter-title-interactive-color); }
  @media (hover: hover) {
    .addresses__filter-title:hover:not(.focus-visible) {
      color: var(--addresses-filter-title-interactive-color); } }
  @media (max-width: 1365px) {
    .addresses__filter-title {
      padding-top: 32px;
      padding-bottom: 32px; }
      .addresses__filter-title svg {
        width: 8px;
        height: 14px;
        margin-left: 14px; } }
  @media (max-width: 767px) {
    .addresses__filter-title {
      padding: 0 var(--container-padding);
      margin-bottom: 8px;
      pointer-events: none; }
      .addresses__filter-title svg {
        display: none; } }

@media (max-width: 767px) {
  .addresses__filter-list-wrapper {
    scrollbar-width: none;
    overflow-x: auto; }
    .addresses__filter-list-wrapper::-webkit-scrollbar {
      display: none; } }

.addresses__filter-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, calc((100% - var(--addresses-filter-gap)) / 2));
  grid-gap: 1.25vw var(--addresses-filter-gap);
  padding: 0 var(--addresses-filter-gap) 1.66667vw; }
  @media (min-width: 768px) {
    .addresses__filter:not(.is-opened) .addresses__filter-list {
      display: none; } }
  @media (max-width: 1365px) {
    .addresses__filter-list {
      grid-row-gap: 24px;
      padding-bottom: 32px; } }
  @media (max-width: 767px) {
    .addresses__filter-list {
      display: flex;
      grid-gap: 0;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0 var(--container-padding); } }

@media (max-width: 767px) {
  .addresses__filter-item {
    margin-right: 12px; }
    .addresses__filter-item:last-child {
      margin-right: 0; } }

.addresses__filter-button {
  --addresses-filter-button-color: #4c4c52;
  --addresses-filter-button-bg: transparent;
  --addresses-filter-button-shadow: none;
  --addresses-filter-icon-color: #acacb9;
  --addresses-filter-button-interactive-color: #000000;
  --addresses-filter-button-interactive-bg: transparent;
  --addresses-filter-button-interactive-shadow: none;
  --addresses-filter-icon-interactive-color: #22cce2;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.005em;
  color: var(--addresses-filter-button-color);
  background-color: var(--addresses-filter-button-bg);
  -webkit-box-shadow: var(--addresses-filter-button-shadow);
          box-shadow: var(--addresses-filter-button-shadow);
  -webkit-transition: color 0.3s ease-in, background-color 0.3s ease-in, -webkit-box-shadow 0.3s ease-in;
  transition: color 0.3s ease-in, background-color 0.3s ease-in, -webkit-box-shadow 0.3s ease-in;
  transition: color 0.3s ease-in, background-color 0.3s ease-in, box-shadow 0.3s ease-in;
  transition: color 0.3s ease-in, background-color 0.3s ease-in, box-shadow 0.3s ease-in, -webkit-box-shadow 0.3s ease-in; }
  .addresses__filter-button svg {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .addresses__filter-button svg {
    width: 1.25vw;
    height: 1.25vw;
    margin-right: 0.41667vw;
    color: var(--addresses-filter-icon-color);
    -webkit-transition: color 0.3s ease-in;
    transition: color 0.3s ease-in; }
  .addresses__filter-button:focus {
    color: var(--addresses-filter-button-interactive-color);
    background-color: var(--addresses-filter-button-interactive-bg);
    -webkit-box-shadow: var(--addresses-filter-button-interactive-shadow);
            box-shadow: var(--addresses-filter-button-interactive-shadow); }
    .addresses__filter-button:focus svg {
      color: var(--addresses-filter-icon-interactive-color); }
  .addresses__filter-button:focus:not(.focus-visible) {
    color: var(--addresses-filter-button-color);
    background-color: var(--addresses-filter-button-bg);
    -webkit-box-shadow: var(--addresses-filter-button-shadow);
            box-shadow: var(--addresses-filter-button-shadow); }
    .addresses__filter-button:focus:not(.focus-visible) svg {
      color: var(--addresses-filter-icon-color); }
  .addresses__filter-button:active:not(.focus-visible), .addresses__filter-button.active:not(.focus-visible) {
    color: var(--addresses-filter-button-interactive-color);
    background-color: var(--addresses-filter-button-interactive-bg);
    -webkit-box-shadow: var(--addresses-filter-button-interactive-shadow);
            box-shadow: var(--addresses-filter-button-interactive-shadow); }
    .addresses__filter-button:active:not(.focus-visible) svg, .addresses__filter-button.active:not(.focus-visible) svg {
      color: var(--addresses-filter-icon-interactive-color); }
  @media (hover: hover) {
    .addresses__filter-button:hover:not(.focus-visible) {
      color: var(--addresses-filter-button-interactive-color);
      background-color: var(--addresses-filter-button-interactive-bg);
      -webkit-box-shadow: var(--addresses-filter-button-interactive-shadow);
              box-shadow: var(--addresses-filter-button-interactive-shadow); }
      .addresses__filter-button:hover:not(.focus-visible) svg {
        color: var(--addresses-filter-icon-interactive-color); } }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .addresses__filter-button {
      font-size: 0.78125vw; } }
  @media (max-width: 1365px) {
    .addresses__filter-button svg {
      width: 24px;
      height: 24px;
      margin-right: 8px; } }
  @media (max-width: 767px) {
    .addresses__filter-button {
      --addresses-filter-button-shadow: inset 0 0 0 1px #ebedef;
      --addresses-filter-button-interactive-bg: #ffffff;
      --addresses-filter-button-interactive-shadow: 0 2px 8px rgba(#000000, 0.02), 0 1px 2px rgba(#000000, 0.08);
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      min-width: 62px;
      padding: 8px;
      padding-top: 16px;
      font-weight: 500;
      font-size: 8px;
      line-height: 1.6;
      letter-spacing: 0;
      border-radius: var(--card-border-radius); }
      .addresses__filter-button svg {
        width: 16px;
        height: 16px;
        margin-right: 0;
        margin-bottom: 4px; } }

.addresses__table,
.addresses__table-head,
.addresses__table-body {
  display: block; }

.addresses__table {
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.006em;
  color: #000000;
  border: 1px solid #ebedef; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .addresses__table {
      font-size: 0.78125vw; } }
  @media (max-width: 767px) {
    .addresses__table {
      font-size: 12px;
      letter-spacing: 0; } }

.addresses__table-row {
  display: grid;
  grid-template-columns: 1fr repeat(2, 27.34375vw);
  border-bottom: 1px solid #ebedef; }
  .addresses__table-body .addresses__table-row:last-child {
    border-bottom: none; }
  @media (max-width: 1365px) {
    .addresses__table-row {
      grid-template-columns: repeat(3, calc(100% / 3)); } }

.addresses__table-cell {
  padding: 0.83333vw;
  border-right: 1px solid #ebedef; }
  .addresses__table-cell ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .addresses__table-cell li {
    margin-bottom: 16px; }
    .addresses__table-cell li:last-child {
      margin-bottom: 0; }
  .addresses__table-cell:last-child {
    border-right: none; }
  .addresses__table-head .addresses__table-cell {
    font-weight: 500; }
  @media (max-width: 1365px) {
    .addresses__table-cell {
      padding: 16px; }
      .addresses__table-cell li {
        margin-bottom: 16px; } }
  @media (max-width: 767px) {
    .addresses__table-cell {
      padding: 12px; }
      .addresses__table-cell li {
        margin-bottom: 10px; } }

.feedback-form {
  --feedback-form-gap: 2.08333vw;
  max-width: 33.33333vw; }
  @media (max-width: 1365px) {
    .feedback-form {
      --feedback-form-gap: 40px;
      max-width: 640px; } }
  @media (max-width: 767px) {
    .feedback-form {
      --feedback-form-gap: 24px;
      max-width: 100%; } }

.feedback-form__title {
  margin-bottom: var(--feedback-form-gap); }

.feedback-form form {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--feedback-form-gap));
  margin-left: calc(-1 * var(--feedback-form-gap)); }

.feedback-form__item {
  margin-top: var(--feedback-form-gap);
  margin-left: var(--feedback-form-gap); }

.feedback-form__field {
  width: 100%; }
  @media (min-width: 768px) {
    .feedback-form__field--half {
      width: calc((100% - 2 * var(--feedback-form-gap)) / 2); } }

.feedback-form__notice {
  width: 100%;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: #acacb9; }
  @media (min-width: 1366px) and (max-width: 1919px) {
    .feedback-form__notice {
      font-size: 0.78125vw; } }
  @media (max-width: 767px) {
    .feedback-form__notice {
      font-size: 10px;
      letter-spacing: 0; } }

.contacts__section {
  margin-bottom: 3.33333vw; }
  .contacts__section:last-child {
    margin-bottom: 0; }
  @media (max-width: 1365px) {
    .contacts__section {
      margin-bottom: 64px; } }

.contacts__section-title {
  margin-bottom: 2.08333vw; }
  @media (max-width: 1365px) {
    .contacts__section-title {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .contacts__section-title {
      margin-bottom: 24px; } }

.contacts__table {
  max-width: 41.66667vw; }
  @media (max-width: 1365px) {
    .contacts__table {
      max-width: 800px; } }
