.back-link {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;

  svg {
    width: to-vw-desktop(112px);
    height: to-vw-desktop(8px);
    margin-top: to-vw-desktop(5px);
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(20px);
  }

  @include tablet-landscape {
    svg {
      width: 112px;
      height: 8px;
      margin-top: 5px;
    }
  }

  @include mobile {
    font-size: 14px;

    svg {
      width: 80px;
      height: 6px;
    }
  }
}
