.link {
  --link-color-default: inherit;
  --link-color-interactive: #{$cyan};

  @include btn-reset;

  color: var(--link-color-default);
  transition: color $transition-props;

  &--underline {
    text-decoration: underline;
  }

  &--reverse {
    --link-color-default: #{$cyan};
    --link-color-interactive: inherit;
  }

  &:not([href]) {
    pointer-events: none;
  }

  &:focus {
    color: var(--link-color-interactive);
  }

  &:focus:not(.focus-visible) {
    color: var(--link-color-default);
  }

  &:active:not(.focus-visible) {
    color: var(--link-color-interactive);
  }

  @include non-touch {
    &:hover:not(.focus-visible) {
      color: var(--link-color-interactive);
    }
  }
}
