.error-page {
  overflow: hidden;
  margin: auto 0;
}


.error-page__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: $secondary-font-family;
  color: $black;

  @include tablet-portrait {
    flex-direction: column;
    align-items: flex-start;
  }
}


.error-page__content {
  flex-shrink: 0;
  width: to-vw-desktop(285px);
  padding-bottom: to-vw-desktop(74px);

  @include tablet-landscape {
    width: 285px;
    padding-bottom: 10px;
  }

  @include tablet-portrait {
    margin-top: -44px;
    padding-bottom: 0;
  }

  @include mobile {
    width: 190px;
    margin-top: -20px;
  }
}


.error-page__title {
  margin: 0;
  font-weight: 700;
  font-size: 128px;
  line-height: 1.1;

  @include desktop-small-only {
    font-size: to-vw-desktop(128px);
  }

  @include mobile {
    font-size: 80px;
  }
}


.error-page__text {
  margin: 0;
  margin-bottom: to-vw-desktop(32px);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include tablet-landscape {
    margin-bottom: 32px;
  }

  @include mobile {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.3;
  }
}


.error-page__image {
  width: to-vw-desktop(905px);
  height: auto;

  @include tablet-landscape {
    width: 635px;
    transform: translateX(10px);
  }

  @include tablet-portrait {
    order: -1;
    width: 100%;
    transform: translateX(25px);
  }

  @include mobile {
    width: 106%;
    transform: translateX(-10px);
  }
}
