.objects__list {
  --objects-gap: #{to-vw-desktop(40px)};
  --objects-columns: 4;

  @include list-reset;

  display: grid;
  grid-template-columns: repeat(var(--objects-columns), 1fr);
  grid-gap: var(--objects-gap);

  @include tablet-landscape {
    --objects-gap: 40px;
    --objects-columns: 2;
  }

  @include tablet-portrait {
    --objects-columns: 1;
  }

  @include mobile {
    --objects-gap: 16px;
  }
}


.objects__item {
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: $gray-light;
  border-radius: var(--card-border-radius);
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-bottom: 74%;
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(13px);
  }

  @include tablet-portrait-only {
    &::before {
      padding-bottom: 54%;
    }
  }

  @include mobile {
    font-size: 8px;
    line-height: 1.6;
    letter-spacing: 0;
  }
}


.objects__item-link {
  @include btn-reset;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: to-vw-desktop(24px);
  user-select: none;

  @include tablet-landscape {
    padding: 24px;
  }

  @include mobile {
    padding: 16px;
  }
}


.objects__item-title {
  margin: 0;
  margin-bottom: to-vw-desktop(16px);
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.005em;
  color: $white;

  @include desktop-small-only {
    font-size: to-vw-desktop(32px);
  }

  @include tablet-landscape {
    margin-bottom: 16px;
  }

  @include mobile {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 21px;
    line-height: 1.1;
    letter-spacing: 0;
  }
}


.objects__item-address {
  margin: 0;
  margin-bottom: to-vw-desktop(4px);

  @include tablet-landscape {
    margin-bottom: 4px;
  }

  @include mobile {
    margin-bottom: 0;
  }
}


.objects__item-year {
  margin: 0;
}


.objects__item-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  img {
    object-fit: cover;
    transition: transform $transition-props;
    will-change: transform;

    .objects__item-link:focus & {
      transform: translate(-50%, -50%) scale(1.1);
    }

    .objects__item-link:focus:not(.focus-visible) & {
      transform: translate(-50%, -50%) scale(1);
    }

    .objects__item-link:active:not(.focus-visible) & {
      transform: translate(-50%, -50%) scale(1.1);
    }

    @include non-touch {
      .objects__item-link:hover:not(.focus-visible) & {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  &::after {
    content: "";
    background-image: linear-gradient(0deg, rgba($black, 0.3), rgba($black, 0.3)), linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.7) 100%);
  }
}
