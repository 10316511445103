.social__list {
  --social-item-size: #{to-vw-desktop(24px)};
  --social-gap: #{to-vw-desktop(8px)};

  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--social-gap));
  margin-left: calc(-1 * var(--social-gap));

  @include tablet-landscape {
    --social-item-size: 24px;
    --social-gap: 8px;
  }
}


.social__item {
  margin-top: var(--social-gap);
  margin-left: var(--social-gap);
}


.social__link {
  display: flex;

  svg {
    width: var(--social-item-size);
    height: var(--social-item-size);
  }

  &--vk {
    --link-color-interactive: #{$blue};
  }

  &--telegram {
    --link-color-interactive: #{$cyan};
  }
}
