$page-header-gap: 40px;


.page-header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: to-vw-desktop(48px) 0;
  color: $black;
  pointer-events: none;

  &--white {
    color: $white;
  }

  .page-body.scroll-locked:not(.permanent-fixed) & {
    padding-right: var(--scrollbar-width, 0);
  }

  @include tablet-landscape {
    padding: 48px 0;
  }

  @include tablet-portrait {
    padding: 40px 0;
  }

  @include mobile {
    padding: 16px 0;
  }
}


.page-header__container,
.page-header__content {
  display: flex;
  align-items: center;
  pointer-events: initial;

  @include tablet-only {
    align-items: flex-start;
  }
}


.page-header__content {
  flex-grow: 1;
  margin-right: $page-header-gap;

  @include tablet-landscape {
    flex-grow: 0;
    margin-right: auto;
  }
}


.page-header__logo-link {
  margin-right: auto;

  svg {
    display: block;
    width: to-vw-desktop(150px);
    height: to-vw-desktop(56px);
  }

  @include tablet-landscape {
    margin-right: 0;

    svg {
      width: 150px;
      height: 56px;
    }
  }

  @include mobile {
    svg {
      width: 100px;
      height: 38px;
    }
  }
}


.page-header__links-list {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 80%;
  margin-top: -#{$page-header-gap / 2};
  margin-left: -#{$page-header-gap};
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  text-transform: uppercase;

  @include desktop-small-only {
    font-size: to-vw-desktop(18px);
  }

  @include tablet-landscape {
    display: none;
  }
}


.page-header__link-item {
  margin-top: #{$page-header-gap / 2};
  margin-left: #{$page-header-gap};
}
