.title {
  margin: 0;
  font-family: $secondary-font-family;
  color: $black;

  &--h1 {
    font-weight: 700;
    font-size: 70px;
    line-height: 1.1;

    @include desktop-small-only {
      font-size: to-vw-desktop(70px);
    }

    @include mobile {
      font-size: 34px;
    }
  }

  &--h2 {
    font-weight: 600;
    font-size: 57px;
    line-height: 1.15;
    letter-spacing: -0.006em;

    @include desktop-small-only {
      font-size: to-vw-desktop(57px);
    }

    @include mobile {
      font-weight: 700;
      font-size: 30px;
    }

    @include min-width {
      font-size: 24px;
    }
  }

  &--h3 {
    font-weight: 600;
    font-size: 42px;
    line-height: 1.15;
    letter-spacing: -0.006em;

    @include desktop-small-only {
      font-size: to-vw-desktop(42px);
    }

    @include mobile {
      font-weight: 700;
      font-size: 25px;
      line-height: 1.1;
    }
  }

  &--h4 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.005em;

    @include desktop-small-only {
      font-size: to-vw-desktop(32px);
    }

    @include mobile {
      font-size: 21px;
      line-height: 1.1;
    }
  }

  &--h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;

    @include desktop-small-only {
      font-size: to-vw-desktop(24px);
    }

    @include mobile {
      font-size: 16px;
      line-height: 1.3;
    }
  }

  &--h6 {
    font-family: $main-font-family;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.005em;

    @include desktop-small-only {
      font-size: to-vw-desktop(20px);
    }

    @include mobile {
      font-size: 14px;
      letter-spacing: 0;
    }
  }
}
