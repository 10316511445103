@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}


@mixin expand-interactive-area($size) {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    transform: translate(-50%, -50%);
  }
}


@mixin hide-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}


@mixin interactive-opacity {
  transition: opacity $transition-props;

  &:focus {
    opacity: 0.5;
  }

  &:focus:not(.focus-visible) {
    opacity: 1;
  }

  &:active:not(.focus-visible) {
    opacity: 0.7;
  }

  @include non-touch {
    &:hover:not(.focus-visible) {
      opacity: 0.8;
    }
  }
}
