/* stylelint-disable declaration-no-important */

.map {
  user-select: none;

  .mapboxgl-canvas-container,
  .mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .mapboxgl-canvas {
    &:focus {
      outline-width: 2px;
      outline-color: $cyan;
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }
  }

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib,
  .mapboxgl-ctrl-icon {
    display: none !important;
  }

  .mapboxgl-ctrl-bottom-right {
    @include desktop-only {
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  .mapboxgl-ctrl {
    margin-right: to-vw-desktop(48px) !important;
    margin-bottom: 0 !important;
    background-color: $white !important;
    border-radius: var(--card-border-radius);
    box-shadow:
      0 2px 8px rgba($black, 0.02),
      0 1px 2px rgba($black, 0.08) !important;

    @include tablet-landscape {
      margin-right: 16px !important;
      margin-bottom: 16px !important;

      @include touch {
        display: none;
      }
    }

    @include mobile {
      display: none;
    }
  }

  .mapboxgl-ctrl button {
    --map-zoom-color-default: #{ $gray-darker };
    --map-zoom-color-interactive: #{ $cyan };

    position: relative;
    width: to-vw-desktop(56px);
    height: to-vw-desktop(56px);
    color: var(--map-zoom-color-default);
    background-color: transparent !important;
    border: none;
    outline: none;
    box-shadow: none !important;
    transition: color $transition-props;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: currentColor;
      border-radius: var(--card-border-radius);
      transform: translate(-50%, -50%);
    }

    &::before {
      width: 18px;
      height: 2px;
    }

    &.mapboxgl-ctrl-zoom-in::after {
      width: 2px;
      height: 18px;
    }

    &:disabled {
      pointer-events: none;
    }

    &:focus {
      color: var(--map-zoom-color-interactive);
    }

    &:focus:not(.focus-visible) {
      color: var(--map-zoom-color-default);
    }

    &:active:not(.focus-visible) {
      color: var(--map-zoom-color-interactive);
    }

    @include non-touch {
      &:hover:not(.focus-visible) {
        color: var(--map-zoom-color-interactive);
      }
    }

    @include tablet-landscape {
      width: 56px;
      height: 56px;
    }

    @include mobile {
      width: 48px;
      height: 48px;
    }
  }

  .mapboxgl-marker {
    --map-marker-size: #{ to-vw-desktop(32px) };
    --map-marker-icon-size: #{ to-vw-desktop(24px) };

    --map-marker-default-color: #{ $white };
    --map-marker-default-bg: #{ $cyan };

    --map-marker-interactive-color: #{ $cyan };
    --map-marker-interactive-bg: #{ $white };

    display: grid;
    place-items: center;
    width: var(--map-marker-size);
    height: var(--map-marker-size);
    color: var(--map-marker-default-color);
    background-color: var(--map-marker-default-bg);
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba($black, 0.02), 0 1px 2px rgba($black, 0.08);
    transition: color $transition-props, background-color $transition-props;
    outline: none;
    cursor: pointer;

    svg {
      width: var(--map-marker-icon-size);
      height: var(--map-marker-icon-size);
      pointer-events: none;
    }

    &:focus {
      z-index: 2;
      color: var(--map-marker-interactive-color);
      background-color: var(--map-marker-interactive-bg);
    }

    &:focus:not(.focus-visible) {
      z-index: initial;
      color: var(--map-marker-default-color);
      background-color: var(--map-marker-default-bg);
    }

    &:active:not(.focus-visible),
    &.active:not(.focus-visible) {
      z-index: 2;
      color: var(--map-marker-interactive-color);
      background-color: var(--map-marker-interactive-bg);
    }

    @include non-touch {
      &:hover:not(.focus-visible) {
        z-index: 2;
        color: var(--map-marker-interactive-color);
        background-color: var(--map-marker-interactive-bg);
      }
    }

    &.is-primary {
      --map-marker-size: #{ to-vw-desktop(40px) };

      --map-marker-default-color: #{ $gray-darker };
      --map-marker-default-bg: #{ $white };

      --map-marker-interactive-color: #{ $white };
      --map-marker-interactive-bg: #{ $gray-darker };
    }

    &.is-disabled {
      pointer-events: none;
    }

    @include tablet-landscape {
      --map-marker-size: 32px;
      --map-marker-icon-size: 24px;

      &.is-primary {
        --map-marker-size: 40px;
      }
    }
  }

  .mapboxgl-popup {
    z-index: 4;
    max-width: to-vw-desktop(260px) !important;
    filter:
      drop-shadow(0 2px 8px rgba($black, 0.02))
      drop-shadow(0 1px 2px rgba($black, 0.08));

    @include tablet-landscape {
      max-width: 260px !important;
    }

    @include mobile {
      max-width: 200px !important;
    }
  }

  .mapboxgl-popup-content {
    padding: to-vw-desktop(24px);
    text-align: center;
    color: $black;
    background-color: $white;
    border-radius: var(--card-border-radius);

    @include tablet-landscape {
      padding: 24px;
    }
  }

  .mapboxgl-popup-tip {
    border: to-vw-desktop(16px) solid transparent;

    @include tablet-landscape {
      border-width: 16px;
    }
  }

  .mapboxgl-popup-close-button {
    --map-popup-btn-color-default: #{ $gray };
    --map-popup-btn-color-interactive: #{ $cyan };

    width: to-vw-desktop(32px);
    height: to-vw-desktop(32px);
    padding: 0;
    font-size: 0;
    color: var(--map-popup-btn-color-default);
    background-color: transparent !important;
    outline: none;
    transition: color $transition-props;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 2px;
      background-color: currentColor;
      border-radius: var(--card-border-radius);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:focus {
      color: var(--map-popup-btn-color-interactive);
    }

    &:focus:not(.focus-visible) {
      color: var(--map-popup-btn-color-default);
    }

    &:active:not(.focus-visible) {
      color: var(--map-popup-btn-color-interactive);
    }

    @include non-touch {
      &:hover:not(.focus-visible) {
        color: var(--map-popup-btn-color-interactive);
      }
    }

    @include tablet-landscape {
      width: 32px;
      height: 32px;
    }
  }

  // фикс просвета

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    transform: translateY(1px);
  }

  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    transform: translateX(-1px);
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    transform: translateY(-1px);
  }

  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    transform: translateX(1px);
  }
}


.map__popup-title {
  margin: 0;
  margin-bottom: to-vw-desktop(8px);
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include tablet-landscape {
    margin-bottom: 8px;
  }

  @include mobile {
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 1.3;
  }
}


.map__popup-text {
  margin: 0;
  margin-bottom: to-vw-desktop(8px);
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em;

  &:last-child {
    margin-bottom: 0;
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(13px);
  }

  @include tablet-landscape {
    margin-bottom: 8px;
  }

  @include mobile {
    margin-bottom: 2px;
    font-size: 8px;
    line-height: 1.6;
    letter-spacing: 0;
  }
}
