.menu {
  --menu-gap: #{to-vw-desktop(38px)};

  @include tablet-landscape {
    --menu-gap: 32px;
  }

  @include mobile {
    --menu-gap: 24px;
  }
}


.menu__button {
  @include btn-reset;
  @include interactive-opacity;
  @include expand-interactive-area(to-vw-desktop(80px));

  position: relative;
  z-index: 20;
  display: block;
  width: to-vw-desktop(40px);
  height: to-vw-desktop(28px);

  @include tablet-landscape {
    @include expand-interactive-area(80px);

    width: 40px;
    height: 28px;
  }

  @include mobile {
    width: 32px;
    height: 21px;
  }
}


.menu__button-line {
  position: absolute;
  left: 0;
  width: 100%;
  height: to-vw-desktop(4px);
  background-color: $cyan;
  transition-property: top, bottom, background-color, transform, opacity;
  transition-timing-function: $transition-function;
  transition-duration: 0.4s;

  &--top {
    top: 0;
  }

  &--center {
    top: 50%;
    transform: translateY(-50%);
  }

  &--bottom {
    bottom: 0;
  }

  .menu__button--open & {
    background-color: $white;

    &--top {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    &--center {
      opacity: 0;
    }

    &--bottom {
      bottom: 50%;
      transform: translateY(50%) rotate(-45deg);
    }
  }

  @include desktop-small-only {
    height: 3px;
  }

  @include tablet-landscape {
    width: 40px;
    height: 4px;
  }

  @include mobile {
    width: 32px;
    height: 3px;
  }
}


.menu__content-wrapper {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: $viewport-height;
  overflow: hidden;
  visibility: hidden;
  transition: visibility 0s $transition-props-long;
  pointer-events: none;

  &--shown {
    visibility: visible;
    transition: visibility 0s 0s;
    pointer-events: initial;
  }
}


.menu__content {
  position: absolute;
  top: 0;
  left: calc(100% - var(--scrollbar-width, 0));
  width: to-vw-desktop(515px);
  height: 100%;
  padding-top: to-vw-desktop(152px);
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.005em;
  color: $gray-light;
  background-color: $black-light;

  // прикрытие зарезервированного под скроллбар места
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: var(--scrollbar-width, 0);
    height: 100%;
    background-color: inherit;
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(32px);
  }

  @include tablet-landscape {
    width: 515px;
    padding-top: 124px;
  }

  @include mobile {
    width: 100%;
    padding-top: 70px;
    font-weight: 700;
    font-size: 21px;
    line-height: 1.1;
    letter-spacing: 0;
  }
}


.menu__container {
  max-height: 100%;
  overflow: auto;

  @include mobile {
    padding-left: 40px;
    padding-right: 40px;
  }
}


.menu__list {
  @include list-reset;

  padding: var(--menu-gap) 0;
  border-bottom: 1px solid $gray-dark;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}


.menu__list-item {
  margin-bottom: var(--menu-gap);

  &:last-child {
    margin-bottom: 0;
  }
}


.menu__list-link {
  display: flex;
  align-items: center;

  svg {
    width: to-vw-desktop(20px);
    height: to-vw-desktop(20px);
    margin-left: to-vw-desktop(10px);
    margin-bottom: 4px;
  }

  @include desktop-small-only {
    margin-bottom: to-vw-desktop(4px);
  }

  @include tablet-landscape {
    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
}
