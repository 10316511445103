.button {
  --button-cover-opacity-default: 0;
  --button-cover-opacity-interactive: 1;

  @include btn-reset;

  position: relative;
  display: inline-flex;
  align-items: center;
  padding: to-vw-desktop(12px) to-vw-desktop(40px);
  font-family: $main-font-family;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: var(--button-cover-opacity-default);
    transition: opacity $transition-props;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }

  &--small {
    padding: to-vw-desktop(8px) to-vw-desktop(24px);
    font-size: 15px;
    line-height: 1.4;

    svg {
      width: 12px;
      height: 12px;
      margin-left: 10px;
    }
  }

  &--bg-white {
    color: $black;
    background-color: $white;
    box-shadow: 0 to-vw-desktop(5px) to-vw-desktop(10px) rgba($black, 0.07), 0 to-vw-desktop(1px) to-vw-desktop(2px) rgba($black, 0.12);

    &::before {
      background-color: rgba($black, 0.05);
    }
  }

  &--bg-cyan {
    color: $white;
    background-color: $cyan;

    &::before {
      background-color: rgba($white, 0.2);
    }
  }

  &--border-white {
    color: $white;
    box-shadow: inset 0 0 0 2px $white;

    &::before {
      background-color: rgba($white, 0.1);
    }
  }

  &--border-black {
    color: $black;
    box-shadow: inset 0 0 0 2px $black;

    &::before {
      background-color: rgba($black, 0.05);
    }
  }

  &:focus::before {
    opacity: var(--button-cover-opacity-interactive);
  }

  &:focus:not(.focus-visible)::before {
    opacity: var(--button-cover-opacity-default);
  }

  &:active:not(.focus-visible)::before {
    opacity: var(--button-cover-opacity-interactive);
  }

  @include non-touch {
    &:hover:not(.focus-visible)::before {
      opacity: var(--button-cover-opacity-interactive);
    }
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(20px);

    svg {
      width: to-vw-desktop(20px);
      height: to-vw-desktop(20px);
      margin-left: to-vw-desktop(15px);
    }

    &--small {
      font-size: to-vw-desktop(15px);

      svg {
        width: to-vw-desktop(12px);
        height: to-vw-desktop(12px);
        margin-left: to-vw-desktop(10px);
      }
    }
  }

  @include tablet-landscape {
    padding: 12px 40px;

    &--small {
      padding: 8px 24px;
    }
  }

  @include mobile {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    line-height: 1.3;

    &--small {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;
    }
  }
}


.button__pointer {
  position: absolute;
  z-index: 2;
  display: block;
  width: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: opacity $transition-props, transform $transition-props-long;

  &::after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  .button--bg-white &,
  .button--border-black & {
    background-color: rgba($black, 0.05);
  }

  .button--bg-cyan &,
  .button--border-white & {
    background-color: rgba($white, 0.1);
  }

  &.shown {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
