.object-content {
  padding-top: to-vw-desktop(40px);

  @include tablet-landscape {
    padding-top: 40px;
  }

  @include mobile {
    padding-top: 20px;
  }
}
