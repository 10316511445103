.notice {
  margin: 0;
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: $black;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include mobile {
    font-size: 16px;
    line-height: 1.3;
  }
}
