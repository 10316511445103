.page-footer {
  --page-footer-columns-count: 4;
  --page-footer-row-gap: #{to-vw-desktop(40px)};
  --page-footer-column-gap: #{to-vw-desktop(40px)};

  padding: to-vw-desktop(40px) 0;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.015em;
  color: $gray-light;
  background-color: $gray-darkest;

  @include desktop-small-only {
    font-size: to-vw-desktop(13px);
  }

  @include tablet-landscape {
    --page-footer-row-gap: 32px;
    --page-footer-column-gap: 40px;

    padding: 40px 0;
  }

  @include tablet-portrait {
    --page-footer-columns-count: 3;
  }

  @include mobile {
    --page-footer-row-gap: 16px;
    --page-footer-column-gap: 16px;

    padding: 24px 0;
  }
}


.page-footer__container {
  @include desktop-only {
    display: grid;
    grid-template-areas:
      "contacts copyright"
      "contacts links";
    grid-template-columns: 1fr to-vw-desktop(300px);
    grid-template-rows: auto 1fr;
    grid-column-gap: var(--page-footer-column-gap);
  }
}


.page-footer__contacts {
  grid-area: contacts;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--page-footer-row-gap));
  margin-left: calc(-1 * var(--page-footer-column-gap));

  @include tablet-portrait {
    flex-wrap: wrap;
  }
}


.page-footer__contacts-section {
  max-width: calc(100% / var(--page-footer-columns-count) - var(--page-footer-row-gap));
  margin-top: var(--page-footer-row-gap);
  margin-left: var(--page-footer-column-gap);

  &--separated {
    flex-shrink: 0;
    max-width: 100%;
    padding-right: var(--page-footer-column-gap);
    border-right: 1px solid $gray-dark;
  }

  @include tablet-portrait {
    max-width: 100%;

    &--separated {
      flex-shrink: initial;
      width: 100%;
      padding-right: 0;
      padding-bottom: var(--page-footer-row-gap);
      border-right: none;
      border-bottom: 1px solid $gray-dark;
    }
  }
}


.page-footer__contacts-title {
  margin: 0;
  font-weight: 500;
  font-size: inherit;
  color: $white;
}


.page-footer__contacts-text {
  margin: 0;
  margin-top: to-vw-desktop(4px);

  &--big {
    margin-top: to-vw-desktop(8px);
    font-family: $secondary-font-family;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    color: $white;
  }

  @include desktop-small-only {
    &--big {
      font-size: to-vw-desktop(24px);
    }
  }

  @include tablet-landscape {
    margin-top: 4px;

    &--big {
      margin-top: 8px;
    }
  }

  @include mobile {
    &--big {
      margin-top: 6px;
      font-size: 16px;
      line-height: 1.3;
    }
  }
}


.page-footer__copyright,
.page-footer__links {
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;

  @include desktop-only {
    text-align: right;
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include mobile {
    font-size: inherit;
    line-height: inherit;
  }
}


.page-footer__copyright {
  grid-area: copyright;
  margin: 0;
  font-weight: 500;
  color: $white;

  @include tablet-landscape {
    margin-top: var(--page-footer-row-gap);
  }

  @include tablet-portrait {
    padding-top: var(--page-footer-row-gap);
    border-top: 1px solid $gray-dark;
  }
}


.page-footer__links {
  @include list-reset;

  grid-area: links;
  margin-top: to-vw-desktop(4px);

  @include tablet-landscape {
    margin-top: 4px;
  }
}
