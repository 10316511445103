@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin btn-reset {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;

  // safari fix
  svg {
    transform: translate(0, 0);
  }
}
