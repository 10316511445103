.inner-page > .container {
  position: relative;
}


.inner-page .back-link {
  @include desktop-only {
    position: absolute;
    top: to-vw-desktop(8px);
    right: calc(100% - #{to-vw-desktop(40px)});
  }

  @include tablet-landscape {
    margin-bottom: 14px;
  }

  @include mobile {
    margin-bottom: 12px;
  }
}


.inner-page__header {
  margin-bottom: to-vw-desktop(48px);

  .title {
    margin-bottom: to-vw-desktop(16px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include tablet-landscape {
    margin-bottom: 48px;
  }

  @include mobile {
    margin-bottom: 24px;

    .title {
      margin-bottom: 16px;
    }
  }
}


.inner-page__header-text {
  margin: 0;
  font-weight: 500;
  color: $black;
}
