.page-content {
  --page-content-padding-top: #{to-vw-desktop(176px)};
  --page-content-padding-bottom: #{to-vw-desktop(80px)};

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--page-content-padding-top);
  padding-bottom: var(--page-content-padding-bottom);

  &--no-padding-top {
    padding-top: 0;
  }

  &--no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include tablet-landscape {
    --page-content-padding-top: 176px;
    --page-content-padding-bottom: 80px;

    &--large-padding-top {
      --page-content-padding-top: 274px;
    }
  }

  @include mobile {
    --page-content-padding-top: 104px;
    --page-content-padding-bottom: 40px;

    &--large-padding-top {
      --page-content-padding-top: 150px;
    }
  }
}
