.object-nav {
  @include hide-scrollbar;

  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  overflow-x: auto;
  padding-top: to-vw-desktop(40px);
  color: $gray;
  background-color: $white;

  @include tablet-landscape {
    padding-top: 40px;
  }

  @include mobile {
    padding-top: 20px;
  }
}


.object-nav__container {
  width: fit-content;
  min-width: 100%;
}


.object-nav__list {
  @include list-reset;

  display: flex;
  padding-bottom: to-vw-desktop(32px);
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  white-space: nowrap;
  border-bottom: 1px solid $gray-lighter;

  @include desktop-small-only {
    font-size: to-vw-desktop(20px);
  }

  @include tablet-landscape {
    padding-bottom: 32px;
  }

  @include mobile {
    padding-bottom: 16px;
    font-size: 14px;
  }
}


.object-nav__item {
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  @include tablet-landscape {
    margin-right: 32px;
  }

  @include mobile {
    margin-right: 16px;
  }
}


.object-nav__link {
  &--current {
    color: var(--link-color-interactive);
  }
}
