.service-page__table,
.service-page__table-head,
.service-page__table-body,
.service-page__table-row,
.service-page__table-cell,
.service-page__subtable,
.service-page__subtable-caption,
.service-page__subtable-body {
  display: block;
}


.service-page__table {
  margin-bottom: to-vw-desktop(40px);
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  user-select: none;

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include tablet-landscape {
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 24px;
    font-size: 10px;
  }
}


.service-page__table-head {
  padding-bottom: to-vw-desktop(24px);
  font-weight: 500;
  color: $black;

  @include tablet-landscape {
    padding-bottom: 24px;
  }

  @include mobile {
    padding-bottom: 16px;
  }
}


.service-page__table-row {
  &--inner {
    display: grid;
    grid-template-columns: 1fr repeat(2, #{to-vw-desktop(205px)});
    grid-column-gap: to-vw-desktop(40px);
    padding-left: to-vw-desktop(32px);
    margin-bottom: to-vw-desktop(16px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include tablet-landscape {
    &--inner {
      grid-template-columns: 1fr repeat(2, 95px);
      grid-column-gap: 40px;
      padding-left: 32px;
      margin-bottom: 16px;
    }
  }

  @include mobile {
    &--inner {
      grid-template-columns: 1fr repeat(2, 55px);
      grid-column-gap: 32px;
      margin-bottom: 12px;
    }
  }
}


.service-page__table-cell {
  padding: 0;
  font-weight: 400;
  text-align: right;

  &:first-child {
    font-weight: 500;
    text-align: left;
  }
}


// .accordion__item-title
.service-page__subtable-caption {
  text-align: left;
}


// .accordion__item-content
.service-page__subtable-body {
  padding: 0;
}


.service-page__button {
  margin-bottom: to-vw-desktop(40px);

  @include tablet-landscape {
    margin-bottom: 40px;
  }

  @include mobile {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 24px;
  }
}
