.documents {
  --documents-gap: #{to-vw-desktop(24px)};
  --documents-columns: 4;

  @include list-reset;

  display: grid;
  grid-template-columns: repeat(var(--documents-columns), calc((100% - (var(--documents-columns) - 1) * var(--documents-gap)) / var(--documents-columns)));
  grid-gap: var(--documents-gap);
  color: $black;

  @include desktop-only {
    .container--narrow & {
      --documents-columns: 2;
    }
  }

  @include tablet-landscape {
    --documents-gap: 24px;
    --documents-columns: 2;
  }

  @include tablet-portrait {
    --documents-columns: 1;
  }

  @include mobile {
    --documents-gap: 12px;
  }
}


.documents__item-link {
  display: flex;
  align-items: center;
  padding: to-vw-desktop(22px) to-vw-desktop(20px);
  border: 1px solid $gray-lighter;
  border-radius: var(--card-border-radius);

  @include tablet-landscape {
    padding: 22px 20px;
  }

  @include mobile {
    padding: 12px;
  }
}


.documents__item-icon {
  flex-shrink: 0;
  width: to-vw-desktop(26px);
  height: to-vw-desktop(26px);
  margin-right: to-vw-desktop(14px);

  &--pdf {
    color: $red;
  }

  &--doc {
    color: $blue-light;
  }

  &--jpg,
  &--png {
    color: $orange;
  }

  @include tablet-landscape {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }

  @include mobile {
    width: 20px;
    height: 20px;
  }
}


.documents__item-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;


  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include mobile {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: 0;
  }
}
