.services__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include mobile {
    display: block;
  }
}


.services .title {
  margin-right: auto;
  margin-bottom: 0;

  @include tablet-landscape {
    width: 100%;
    margin-bottom: 24px;
    margin-left: 0;
  }

  @include mobile {
    text-align: center;
  }
}


.services__button {
  margin-left: 16px;

  @include tablet-landscape {
    margin-right: 16px;
    margin-left: 0;
  }

  @include mobile {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.services__list {
  @include list-reset;

  --services-gap: #{to-vw-desktop(40px)};
  --services-columns: 4;

  display: grid;
  grid-template-columns: repeat(var(--services-columns), 1fr);
  grid-gap: var(--services-gap);
  margin-bottom: to-vw-desktop(40px);

  @include tablet-landscape {
    --services-gap: 40px;
    --services-columns: 2;

    margin-bottom: 40px;
  }

  @include mobile {
    --services-gap: 16px;

    grid-template-columns: 100%;
    margin-bottom: 16px;
  }
}


.services__item-link {
  @include btn-reset;
  @include interactive-opacity;

  display: flex;
  flex-direction: column;
  height: 100%;
  padding: to-vw-desktop(24px);
  border: 2px solid $gray-lighter;
  border-radius: var(--card-border-radius);

  @include tablet-landscape {
    padding: 24px;
  }

  @include mobile {
    display: grid;
    grid-template-areas:
      "icon title"
      "icon count";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    padding: 16px;
  }
}


.services__item-icon {
  display: grid;
  place-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background-color: $gray-lightest;
  border-radius: 50%;

  svg {
    width: 48px;
    height: 48px;
  }

  @include tablet-landscape {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;

    svg {
      width: 48px;
      height: 48px;
    }
  }

  @include mobile {
    grid-area: icon;
    width: 64px;
    height: 64px;
    margin-right: 16px;
    margin-bottom: 0;

    svg {
      width: 38px;
      height: 38px;
    }
  }
}


.services__item-title {
  margin-top: auto;
  margin-bottom: to-vw-desktop(12px);
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: $black;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include tablet-landscape {
    margin-bottom: 12px;
  }

  @include mobile {
    grid-area: title;
    font-size: 16px;
    line-height: 1.3;
  }

  @include min-width {
    font-size: 14px;
  }
}


.services__item-count {
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: $cyan;

  @include desktop-small-only {
    font-size: to-vw-desktop(13px);
  }

  @include mobile {
    grid-area: count;
    font-size: 8px;
    line-height: 1.6;
    letter-spacing: 0;
  }
}
