html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}


body {
  padding: 0;
  margin: 0;

  // отключение увеличения шрифта в ландшафтном режиме на iOS
  text-size-adjust: 100%;

  // отключение подсветки элементов при тапе на iOS
  -webkit-tap-highlight-color: transparent;

  // улучшение отображения шрифтов на Mac
  -webkit-font-smoothing: antialiased;
}

// дополнительный фикс шрифтов на Mac для Firefox
@-moz-document url-prefix("") {
  body {
    -moz-osx-font-smoothing: grayscale;
  }
}

button,
input,
input::placeholder,
textarea,
textarea::placeholder,
select {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
}

a {
  text-decoration-skip-ink: none;
}


// фикс взаимодействия с длинными числами
// как с номером телефона
.ios-num-fix * {
  color: inherit;
  text-decoration: none;
  pointer-events: none;
}
