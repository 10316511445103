.pagination {
  --pagination-button-size: #{to-vw-desktop(36px)};
  --pagination-gap: #{to-vw-desktop(8px)};

  padding-top: to-vw-desktop(40px);

  @include tablet-landscape {
    --pagination-button-size: 36px;
    --pagination-gap: 8px;

    padding-top: 40px;
  }

  @include mobile {
    --pagination-button-size: 30px;

    padding-top: 20px;
  }

  @include min-width {
    --pagination-button-size: 24px;
  }
}


.pagination__list {
  @include list-reset;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--pagination-button-size), auto));
  grid-gap: var(--pagination-gap);
  justify-content: center;
}


.pagination__button {
  --pagination-button-color-default: #{$gray};
  --pagination-button-bg-default: #{$gray-lightest};
  --pagination-button-border-default: #{$gray-lighter};

  --pagination-button-color-interactive: #{$white};
  --pagination-button-bg-interactive: #{$black-light};
  --pagination-button-border-interactive: #{$black-light};

  @include btn-reset;

  display: grid;
  place-items: center;
  width: var(--pagination-button-size);
  height: var(--pagination-button-size);
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--pagination-button-color-default);
  background-color: var(--pagination-button-bg-default);
  border: 1px solid var(--pagination-button-border-default);
  transition-property: color, background-color, border-color;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-function;

  &--arrow {
    --pagination-button-color-default: inherit;
    --pagination-button-bg-default: transparent;
    --pagination-button-border-default: transparent;

    --pagination-button-color-interactive: #{$blue};
    --pagination-button-bg-interactive: transparent;
    --pagination-button-border-interactive: transparent;

    &.pagination__button--disabled {
      --pagination-button-color-default: #{$gray-lighter};
      --pagination-button-bg-default: transparent;
      --pagination-button-border-default: transparent;

      --pagination-button-color-interactive: #{$gray-lighter};
      --pagination-button-bg-interactive: transparent;
      --pagination-button-border-interactive: transparent;

      pointer-events: none;
    }

    svg {
      width: to-vw-desktop(8px);
      height: to-vw-desktop(14px);
    }
  }

  &--prev {
    margin-right: to-vw-desktop(24px);
  }

  &--next {
    margin-left: to-vw-desktop(24px);

    svg {
      transform: rotate(180deg);
    }
  }

  &--current {
    color: var(--pagination-button-color-interactive);
    background-color: var(--pagination-button-bg-interactive);
    border-color: var(--pagination-button-border-interactive);
    pointer-events: none;
  }

  &:focus {
    color: var(--pagination-button-color-interactive);
    background-color: var(--pagination-button-bg-interactive);
    border-color: var(--pagination-button-border-interactive);
  }

  &:focus:not(.focus-visible) {
    color: var(--pagination-button-color-default);
    background-color: var(--pagination-button-bg-default);
    border-color: var(--pagination-button-border-default);
  }

  &:active:not(.focus-visible) {
    color: var(--pagination-button-color-interactive);
    background-color: var(--pagination-button-bg-interactive);
    border-color: var(--pagination-button-border-interactive);
  }

  @include non-touch {
    &:hover:not(.focus-visible) {
      color: var(--pagination-button-color-interactive);
      background-color: var(--pagination-button-bg-interactive);
      border-color: var(--pagination-button-border-interactive);
    }
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include tablet-landscape {
    &--arrow {
      svg {
        width: 8px;
        height: 14px;
      }
    }

    &--prev {
      margin-right: 24px;
    }

    &--next {
      margin-left: 24px;
    }
  }

  @include mobile {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0;

    &--prev {
      margin-right: 0;
    }

    &--next {
      margin-left: 0;
    }
  }
}
