.feedback-form {
  --feedback-form-gap: #{ to-vw-desktop(40px) };

  max-width: to-vw-desktop(640px);

  @include tablet-landscape {
    --feedback-form-gap: 40px;

    max-width: 640px;
  }

  @include mobile {
    --feedback-form-gap: 24px;

    max-width: 100%;
  }
}


.feedback-form__title {
  margin-bottom: var(--feedback-form-gap);
}


.feedback-form form {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--feedback-form-gap));
  margin-left: calc(-1 * var(--feedback-form-gap));
}


.feedback-form__item {
  margin-top: var(--feedback-form-gap);
  margin-left: var(--feedback-form-gap);
}


.feedback-form__field {
  width: 100%;

  @include not-mobile {
    &--half {
      width: calc((100% - 2 * var(--feedback-form-gap)) / 2);
    }
  }
}


.feedback-form__notice {
  width: 100%;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: $gray;

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include mobile {
    font-size: 10px;
    letter-spacing: 0;
  }
}
