.form-field {
  position: relative;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 1.6;

  &--valid {
    color: $green;
  }

  &--invalid {
    color: $red;
  }

  @include desktop-small-only {
    padding-bottom: to-vw-desktop(20px);
    font-size: to-vw-desktop(20px);
  }

  @include mobile {
    padding-bottom: 16px;
    font-size: 14px;
  }
}


.form-field__input {
  display: block;
  width: 100%;
  padding: 0;
  padding-right: to-vw-desktop(28px);
  padding-bottom: to-vw-desktop(8px);
  color: $black;
  border: none;
  border-bottom: 2px solid $gray;
  border-radius: 0;
  outline: none;
  appearance: none;
  transition: border-color $transition-props;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px $white inset;
  }

  &::placeholder {
    color: $gray-darker;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    border-color: $blue-dark;
  }

  .form-field--valid & {
    border-color: $green;
  }

  .form-field--invalid & {
    border-color: $red;
  }

  @include tablet-landscape {
    padding-right: 28px;
    padding-bottom: 8px;
  }

  @include mobile {
    padding-bottom: 12px;
  }
}


.form-field__icon {
  position: absolute;
  top: 8px;
  right: 0;
  display: none;
  width: to-vw-desktop(20px);
  height: to-vw-desktop(20px);

  .form-field--valid & {
    &--valid {
      display: block;
    }
  }

  .form-field--invalid & {
    &--invalid {
      display: block;
    }
  }

  @include desktop-small-only {
    top: to-vw-desktop(8px);
  }

  @include tablet-landscape {
    width: 20px;
    height: 20px;
  }

  @include mobile {
    top: 4px;
  }
}


.form-field__message {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  margin: 0;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.02em;

  .form-field--valid & {
    &--valid {
      display: block;
    }
  }

  .form-field--value-missing & {
    &--value-missing {
      display: block;
    }
  }

  .form-field--pattern-mismatch & {
    &--pattern-mismatch {
      display: block;
    }
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(13px);
  }

  @include mobile {
    font-size: 10px;
  }
}
