.datetime {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: $black;

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include mobile {
    font-size: 10px;
    letter-spacing: 0;
  }
}


.datetime__item {
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    margin: 0 8px;
    width: 2px;
    height: 2px;
    background-color: currentColor;
    border-radius: 50%;
  }
}
