.payment__features {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  color: $black;
}


.payment__feature {
  padding-right: to-vw-desktop(16px);
  margin-right: to-vw-desktop(16px);
  border-right: 1px solid $gray-lighter;

  &:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  @include tablet-landscape {
    padding-right: 16px;
    margin-right: 16px;
  }
}


.payment__options {
  --payment-options-column-width: #{to-vw-desktop(410px)};
  --payment-options-gap: #{to-vw-desktop(40px)};
  --payment-options-columns-count: 2;

  @include list-reset;

  display: grid;
  grid-template-columns: repeat(var(--payment-options-columns-count), var(--payment-options-column-width));
  grid-gap: var(--payment-options-gap);

  @include tablet-landscape {
    --payment-options-column-width: 410px;
    --payment-options-gap: 40px;
  }

  @include tablet-portrait {
    --payment-options-gap: 24px;
    --payment-options-columns-count: 1;
  }

  @include mobile {
    --payment-options-column-width: 100%;
    --payment-options-gap: 16px;
  }
}


.payment__option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: to-vw-desktop(32px);
  color: $white;
  background-color: $cyan;
  border-radius: var(--card-border-radius);
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: to-vw-desktop(220px);
    height: to-vw-desktop(250px);
    transform: rotate(30deg);
  }

  &::before {
    background-color: rgba($cyan-dark, 0.3);
  }

  &::after {
    background-color: $cyan-light;
    box-shadow: 0 to-vw-desktop(28px) to-vw-desktop(71px) rgba($black, 0.04), 0 to-vw-desktop(10px) to-vw-desktop(22px) rgba($black, 0.05), 0 to-vw-desktop(2px) to-vw-desktop(6px) rgba($black, 0.05);
  }

  &:nth-child(4n+1) {
    &::before {
      right: -#{to-vw-desktop(64px)};
      bottom: -#{to-vw-desktop(114px)};
    }

    &::after {
      right: -#{to-vw-desktop(80px)};
      bottom: -#{to-vw-desktop(154px)};
    }
  }

  &:nth-child(4n+2) {
    &::before {
      bottom: -#{to-vw-desktop(146px)};
      left: -#{to-vw-desktop(136px)};
    }

    &::after {
      bottom: -#{to-vw-desktop(198px)};
      left: -#{to-vw-desktop(98px)};
    }
  }

  &:nth-child(4n+3) {
    &::before {
      top: -#{to-vw-desktop(110px)};
      right: -#{to-vw-desktop(92px)};
      width: to-vw-desktop(280px);
    }

    &::after {
      right: -#{to-vw-desktop(80px)};
      top: -#{to-vw-desktop(136px)};
    }
  }

  &:nth-child(4n+4) {
    &::before {
      top: -#{to-vw-desktop(138px)};
      left: -#{to-vw-desktop(136px)};
    }

    &::after {
      top: -#{to-vw-desktop(92px)};
      left: -#{to-vw-desktop(98px)};
    }
  }

  @include tablet-landscape {
    padding: 32px;

    &::before,
    &::after {
      width: 220px;
      height: 250px;
    }

    &::after {
      box-shadow: 0 28px 71px rgba($black, 0.04), 0 10px 22px rgba($black, 0.05), 0 2px 6px rgba($black, 0.05);
    }

    &:nth-child(4n+1) {
      &::before {
        right: -64px;
        bottom: -114px;
      }

      &::after {
        right: -80px;
        bottom: -154px;
      }
    }

    &:nth-child(4n+2) {
      &::before {
        bottom: -146px;
        left: -136px;
      }

      &::after {
        bottom: -198px;
        left: -98px;
      }
    }

    &:nth-child(4n+3) {
      &::before {
        top: -110px;
        right: -92px;
        width: 280px;
      }

      &::after {
        right: -80px;
        top: -136px;
      }
    }

    &:nth-child(4n+4) {
      &::before {
        top: -138px;
        left: -136px;
      }

      &::after {
        top: -92px;
        left: -98px;
      }
    }
  }

  @include tablet-portrait {
    min-height: 212px;

    &:nth-child(4n+1) {
      &::before {
        right: -64px;
        bottom: -114px;
      }

      &::after {
        right: -80px;
        bottom: -154px;
      }
    }

    &:nth-child(4n+2) {
      &::before {
        top: -124px;
        right: -48px;
        bottom: initial;
        left: initial;
      }

      &::after {
        top: -136px;
        right: -90px;
        bottom: initial;
        left: initial;
      }
    }

    &:nth-child(4n+3) {
      &::before {
        top: initial;
        right: initial;
        bottom: -170px;
        left: -115px;
      }

      &::after {
        top: initial;
        right: initial;
        bottom: -198px;
        left: -98px;
      }
    }

    &:nth-child(4n+4) {
      &::before {
        top: -110px;
        left: -56px;
      }

      &::after {
        top: -92px;
        left: -98px;
      }
    }
  }

  @include mobile {
    min-height: 150px;
    padding: 24px;

    &:nth-child(4n+1) {
      &::before {
        right: -84px;
        bottom: -174px;
      }

      &::after {
        right: -98px;
        bottom: -215px;
      }
    }

    &:nth-child(4n+2) {
      &::before {
        top: -134px;
        right: -110px;
      }

      &::after {
        top: -145px;
        right: -155px;
      }
    }

    &:nth-child(4n+3) {
      &::before {
        bottom: -195px;
      }

      &::after {
        bottom: -218px;
      }
    }

    &:nth-child(4n+4) {
      &::before {
        top: -140px;
        left: -84px;
      }

      &::after {
        top: -178px;
        left: -94px;
      }
    }
  }
}


.payment__option-title,
.payment__option-button {
  position: relative;
  z-index: 2;
}


.payment__option-title {
  margin: 0;
  margin-bottom: 24px;
  font-family: $secondary-font-family;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  @include desktop-small-only {
    font-size: to-vw-desktop(24px);
  }

  @include tablet-landscape {
    margin-bottom: 24px;
  }

  @include mobile {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.3;
  }
}


.payment__option-button {
  display: flex;
  align-items: center;
  margin-top: auto;
  box-shadow: 0 to-vw-desktop(5px) to-vw-desktop(10px) rgba($black, 0.07), 0 to-vw-desktop(1px) to-vw-desktop(2px) rgba($black, 0.12);

  @include tablet-landscape {
    box-shadow: 0 5px 10px rgba($black, 0.07), 0 1px 2px rgba($black, 0.12);
  }
}
