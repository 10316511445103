.reader {
  height: $viewport-height;
  background-color: $gray-lightest;

  &--inner {
    height: to-vw-desktop(870px);
    border: 1px solid $gray-lighter;
  }

  @include tablet-landscape {
    &--inner {
      height: 510px;
    }
  }

  @include tablet-portrait-only {
    &--inner {
      height: 800px;
    }
  }

  @include mobile {
    &--inner {
      @include out-of-container;
    }
  }
}
