.text-content__section {
  margin-bottom: to-vw-desktop(48px);

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    margin-bottom: 48px;
  }

  @include mobile {
    margin-bottom: 32px;

    &--subsection {
      margin-bottom: 24px;
    }
  }
}


.text-content .title {
  &--h1 {
    margin-bottom: to-vw-desktop(48px);
  }

  &--h2 {
    margin-bottom: to-vw-desktop(48px);
  }

  &--h3 {
    margin-bottom: to-vw-desktop(48px);
  }

  &--h4 {
    margin-bottom: to-vw-desktop(32px);
  }

  &--h5 {
    margin-bottom: to-vw-desktop(40px);
  }

  &--h6 {
    margin-bottom: to-vw-desktop(16px);
  }

  @include tablet-landscape {
    &--h1 {
      margin-bottom: 48px;
    }

    &--h2 {
      margin-bottom: 48px;
    }

    &--h3 {
      margin-bottom: 48px;
    }

    &--h4 {
      margin-bottom: 32px;
    }

    &--h5 {
      margin-bottom: 40px;
    }

    &--h6 {
      margin-bottom: 16px;
    }
  }

  @include mobile {
    &--h1 {
      margin-bottom: 32px;
    }

    &--h2 {
      margin-bottom: 32px;
    }

    &--h3 {
      margin-bottom: 24px;
    }

    &--h4 {
      margin-bottom: 16px;
    }

    &--h5 {
      margin-bottom: 16px;
    }

    &--h6 {
      margin-bottom: 8px;
    }
  }
}


.text-content__disclaimer {
  margin-bottom: to-vw-desktop(40px);

  &:last-child {
    margin-bottom: 0;
  }

  @include desktop-only {
    max-width: to-vw-desktop(1160px);

    &--small {
      max-width: to-vw-desktop(850px);
    }
  }

  @include tablet-landscape {
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 16px;
  }
}


.text-content .text,
.text-content .notice,
.text-content .table,
.text-content .image {
  margin-bottom: to-vw-desktop(48px);

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    margin-bottom: 48px;
  }

  @include mobile {
    margin-bottom: 24px;
  }
}
