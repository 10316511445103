.page-body {
  min-width: $width-min;
  font-family: $main-font-family;
  font-size: 20px;
  line-height: 1.6;
  color: $gray-darker;

  &.fixed,
  &.permanent-fixed {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }

  &.scroll-locked:not(.permanent-fixed) {
    padding-right: var(--scrollbar-width, 0);
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(20px);
  }

  @include mobile {
    font-size: 14px;
  }
}


.page-body__content {
  display: flex;
  flex-direction: column;
  min-height: $viewport-height;
}
