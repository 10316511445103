.accordion {
  border-top: 1px solid $gray-lighter;
}


.accordion__item {
  border-bottom: 1px solid $gray-lighter;
}


.accordion__item-title {
  --accordion-item-title-color-default: #{$black};
  --accordion-item-title-color-interactive: #{$cyan};

  position: relative;
  padding: to-vw-desktop(24px) 0;
  padding-left: to-vw-desktop(32px);
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  color: var(--accordion-item-title-color-default);
  cursor: pointer;
  outline: none;
  transition: color $transition-props;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: to-vw-desktop(5px);
    width: 14px;
    height: 2px;
    background-color: var(--accordion-item-title-color-interactive);
    border-radius: var(--card-border-radius);
    transform: translateY(-50%);
    transition: transform $transition-props;
  }

  &::before {
    transform: translateY(-50%) rotate(90deg);
  }

  .accordion__item.is-open & {
    &::before {
      transform: translateY(-50%) rotate(0);
    }

    &::after {
      transform: translateY(-50%) rotate(-90deg);
      visibility: hidden;
      transition: transform $transition-props, visibility 0s $transition-props;
    }
  }

  &:focus {
    color: var(--accordion-item-title-color-interactive);
  }

  &:focus:not(.focus-visible) {
    color: var(--accordion-item-title-color-default);
  }

  &:active:not(.focus-visible) {
    color: var(--accordion-item-title-color-interactive);
  }

  @include non-touch {
    &:hover:not(.focus-visible) {
      color: var(--accordion-item-title-color-interactive);
    }
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(20px);
  }

  @include tablet-landscape {
    padding: 24px 0;
    padding-left: 32px;

    &::before,
    &::after {
      left: 5px;
    }
  }

  @include mobile {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
  }
}


.accordion__item-content {
  height: 0;
  padding-left: to-vw-desktop(110px);
  overflow: hidden;
  visibility: hidden;
  transition: height $transition-props-long, visibility 0s $transition-props-long;

  &::after {
    content: "";
    display: block;
    height: to-vw-desktop(24px);
  }

  .accordion__item.is-open & {
    visibility: visible;
    transition: height $transition-props-long, visibility 0s 0s;
  }

  @include tablet-landscape {
    padding-left: 80px;

    &::after {
      height: 24px;
    }
  }

  @include mobile {
    padding-left: 56px;

    &::after {
      height: 16px;
    }
  }

  @include min-width {
    padding-left: 32px;
  }
}
