@mixin touch {
  @media (hover: none) {
    @content;
  }
}

@mixin non-touch {
  @media (hover: hover) {
    @content;
  }
}
