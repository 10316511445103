:root {
  --card-border-radius: #{to-vw-desktop(2px)};

  @include tablet-landscape {
    --card-border-radius: 2px;
  }
}


.container {
  @include container;
}

.visually-hidden {
  @include visually-hidden;
}

/* stylelint-disable declaration-no-important */
.hidden {
  display: none !important;
}
/* stylelint-enable declaration-no-important */
