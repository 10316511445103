/* stylelint-disable */
@mixin font-face($name, $filename, $weight, $style) {
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: url(#{"../font/" + $filename + ".woff2"}) format("woff2");
  }
}
/* stylelint-enable */
