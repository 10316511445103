@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}


@mixin desktop-medium {
  @media (max-width: $width-desktop-large - 1) {
    @content;
  }
}

@mixin desktop-small {
  @media (max-width: $width-desktop - 1) {
    @content;
  }
}

@mixin desktop-small-only {
  @media (min-width: $width-desktop-small) and (max-width: $width-desktop - 1) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $width-desktop-small) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (max-width: $width-desktop-small - 1px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: $width-tablet-landscape - 1px) {
    @content;
  }
}

@mixin tablet-portrait-only {
  @media (min-width: $width-tablet-portrait) and  (max-width: $width-tablet-landscape - 1px) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: $width-tablet-portrait) and (max-width: $width-desktop-small - 1px) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: $width-tablet-portrait) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $width-tablet-portrait - 1px) {
    @content;
  }
}

@mixin min-width {
  @media (max-width: $width-mobile - 1px) {
    @content;
  }
}


@mixin container {
  --container-padding: #{to-vw-desktop(80px)};

  width: 100%;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin: 0 auto;

  @include desktop-only {
    &--medium {
      max-width: to-vw-desktop($medium-container-max-width);
    }

    &--narrow {
      max-width: to-vw-desktop($narrow-container-max-width);
    }
  }

  @include tablet-landscape {
    --container-padding: 40px;
  }

  @include mobile {
    --container-padding: 16px;
  }
}

@mixin out-of-container {
  margin-left: calc(-1 * var(--container-padding));
  margin-right: calc(-1 * var(--container-padding));
}
