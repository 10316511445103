.addresses__map-wrapper {
  position: relative;
  margin-bottom: to-vw-desktop(40px);

  @include tablet-landscape {
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 20px;
  }
}


.addresses__map-container {
  height: to-vw-desktop(775px);

  @include tablet-landscape {
    height: 510px;
  }

  @include tablet-portrait {
    height: 800px;
  }

  @include mobile {
    @include out-of-container;

    height: 430px;
  }
}


.addresses__filter {
  --addresses-filter-gap: #{ to-vw-desktop(40px) };

  position: absolute;
  z-index: 2;
  left: to-vw-desktop(16px);
  bottom: to-vw-desktop(16px);
  width: to-vw-desktop(450px);
  background-color: $white;
  box-shadow:
    0 2px 8px rgba($black, 0.08),
    0 1px 2px rgba($black, 0.16);
  border-radius: var(--card-border-radius);

  @include tablet-landscape {
    --addresses-filter-gap: 40px;

    left: 16px;
    bottom: 16px;
    width: 450px;
  }

  @include mobile {
    @include out-of-container;

    position: sticky;
    left: initial;
    bottom: 0;
    width: auto;
    padding: 16px 0;
    background-color: $gray-lightest;
    box-shadow: none;
    border-radius: 0;
  }
}


.addresses__filter-title {
  --addresses-filter-title-default-color: #{ $black };
  --addresses-filter-title-interactive-color: #{ $cyan };

  display: flex;
  align-items: center;
  padding: to-vw-desktop(32px) var(--addresses-filter-gap);
  margin: 0;
  color: var(--addresses-filter-title-default-color);
  outline: none;
  transition: color $transition-props;
  user-select: none;
  cursor: pointer;

  svg {
    width: to-vw-desktop(8px);
    height: to-vw-desktop(14px);
    margin-left: to-vw-desktop(14px);
    transform: rotate(90deg);
    transition: transform $transition-props;
  }

  .addresses__filter.is-opened & {
    svg {
      transform: rotate(-90deg);
    }
  }

  &:focus {
    color: var(--addresses-filter-title-interactive-color);
  }

  &:focus:not(.focus-visible) {
    color: var(--addresses-filter-title-default-color);
  }

  &:active:not(.focus-visible) {
    color: var(--addresses-filter-title-interactive-color);
  }

  @include non-touch {
    &:hover:not(.focus-visible) {
      color: var(--addresses-filter-title-interactive-color);
    }
  }

  @include tablet-landscape {
    padding-top: 32px;
    padding-bottom: 32px;

    svg {
      width: 8px;
      height: 14px;
      margin-left: 14px;
    }
  }

  @include mobile {
    padding: 0 var(--container-padding);
    margin-bottom: 8px;
    pointer-events: none;

    svg {
      display: none;
    }
  }
}


.addresses__filter-list-wrapper {
  @include mobile {
    @include hide-scrollbar;

    overflow-x: auto;
  }
}


.addresses__filter-list {
  @include list-reset;

  display: grid;
  grid-template-columns: repeat(2, calc((100% - var(--addresses-filter-gap)) / 2));
  grid-gap: to-vw-desktop(24px) var(--addresses-filter-gap);
  padding: 0 var(--addresses-filter-gap) to-vw-desktop(32px);

  @include not-mobile {
    .addresses__filter:not(.is-opened) & {
      display: none;
    }
  }

  @include tablet-landscape {
    grid-row-gap: 24px;
    padding-bottom: 32px;
  }

  @include mobile {
    display: flex;
    grid-gap: 0;
    width: fit-content;
    padding: 0 var(--container-padding);
  }
}


.addresses__filter-item {
  @include mobile {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}


.addresses__filter-button {
  --addresses-filter-button-color: #{ $gray-darker };
  --addresses-filter-button-bg: transparent;
  --addresses-filter-button-shadow: none;
  --addresses-filter-icon-color: #{ $gray };

  --addresses-filter-button-interactive-color: #{ $black };
  --addresses-filter-button-interactive-bg: transparent;
  --addresses-filter-button-interactive-shadow: none;
  --addresses-filter-icon-interactive-color: #{ $cyan };

  @include btn-reset;

  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.005em;
  color: var(--addresses-filter-button-color);
  background-color: var(--addresses-filter-button-bg);
  box-shadow: var(--addresses-filter-button-shadow);
  transition: color $transition-props, background-color $transition-props, box-shadow $transition-props;

  svg {
    width: to-vw-desktop(24px);
    height: to-vw-desktop(24px);
    margin-right: to-vw-desktop(8px);
    color: var(--addresses-filter-icon-color);
    transition: color $transition-props;
  }

  &:focus {
    color: var(--addresses-filter-button-interactive-color);
    background-color: var(--addresses-filter-button-interactive-bg);
    box-shadow: var(--addresses-filter-button-interactive-shadow);

    svg {
      color: var(--addresses-filter-icon-interactive-color);
    }
  }

  &:focus:not(.focus-visible) {
    color: var(--addresses-filter-button-color);
    background-color: var(--addresses-filter-button-bg);
    box-shadow: var(--addresses-filter-button-shadow);

    svg {
      color: var(--addresses-filter-icon-color);
    }
  }

  &:active:not(.focus-visible),
  &.active:not(.focus-visible) {
    color: var(--addresses-filter-button-interactive-color);
    background-color: var(--addresses-filter-button-interactive-bg);
    box-shadow: var(--addresses-filter-button-interactive-shadow);

    svg {
      color: var(--addresses-filter-icon-interactive-color);
    }
  }

  @include non-touch {
    &:hover:not(.focus-visible) {
      color: var(--addresses-filter-button-interactive-color);
      background-color: var(--addresses-filter-button-interactive-bg);
      box-shadow: var(--addresses-filter-button-interactive-shadow);

      svg {
        color: var(--addresses-filter-icon-interactive-color);
      }
    }
  }

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include tablet-landscape {
    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  @include mobile {
    --addresses-filter-button-shadow: inset 0 0 0 1px #{ $gray-lighter };
    --addresses-filter-button-interactive-bg: #{ $white };
    --addresses-filter-button-interactive-shadow: 0 2px 8px rgba(#{ $black }, 0.02), 0 1px 2px rgba(#{ $black }, 0.08);

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-width: 62px;
    padding: 8px;
    padding-top: 16px;
    font-weight: 500;
    font-size: 8px;
    line-height: 1.6;
    letter-spacing: 0;
    border-radius: var(--card-border-radius);

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
      margin-bottom: 4px;
    }
  }
}


.addresses__table,
.addresses__table-head,
.addresses__table-body {
  display: block;
}


.addresses__table {
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.006em;
  color: $black;
  border: 1px solid $gray-lighter;

  @include desktop-small-only {
    font-size: to-vw-desktop(15px);
  }

  @include mobile {
    font-size: 12px;
    letter-spacing: 0;
  }
}


.addresses__table-row {
  display: grid;
  grid-template-columns: 1fr repeat(2, #{ to-vw-desktop(525px) });
  border-bottom: 1px solid $gray-lighter;

  .addresses__table-body & {
    &:last-child {
      border-bottom: none;
    }
  }

  @include tablet-landscape {
    grid-template-columns: repeat(3, calc(100% / 3));
  }
}


.addresses__table-cell {
  padding: to-vw-desktop(16px);
  border-right: 1px solid $gray-lighter;

  ul {
    @include list-reset;
  }

  li {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    border-right: none;
  }

  .addresses__table-head & {
    font-weight: 500;
  }

  @include tablet-landscape {
    padding: 16px;

    li {
      margin-bottom: 16px;
    }
  }

  @include mobile {
    padding: 12px;

    li {
      margin-bottom: 10px;
    }
  }
}
